/** Angular Imports */
import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';

/** rxjs Imports */
import {from, Observable, throwError} from 'rxjs';

/** Custom Imports */
import { environment } from '../../../environments/environment';
import { SettingsService } from 'app/settings/settings.service';
import { catchError, switchMap } from 'rxjs/operators';
import {KeycloakService} from 'keycloak-angular';

/** Http request (default) options headers. */
const httpOptions = {
  headers: {
    'Fineract-Platform-TenantId': environment.fineractPlatformTenantId,
  },
};

/** Authorization header. */
const authorizationHeader = 'Authorization';
/** Two factor access token header. */
const twoFactorAccessTokenHeader = 'Fineract-Platform-TFA-Token';

/**
 * Http Request interceptor to set the request headers.
 */
@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {
  constructor(
    private settingsService: SettingsService,
    private keycloakService: KeycloakService,
  ) {}

  /**
   * Intercepts a Http request and sets the request headers.
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.settingsService.tenantIdentifier) {
      httpOptions.headers['Fineract-Platform-TenantId'] = this.settingsService.tenantIdentifier;
    }
    request = request.clone({ setHeaders: httpOptions.headers });
    return next.handle(request).pipe(catchError((error) => this.handleError(error, request, next)));
  }

  // handle refresh token if request error is 401
  handleError(error: HttpErrorResponse, request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (error.status === 401) {
      // If 401 Unauthorized, we try to refresh the token
      return this.refreshToken().pipe(
        switchMap(() => {
          // If refresh is successful, we make the request again with the new token
          const clonedRequest = request.clone({
            setHeaders: { Authorization: `Bearer ${this.getAccessToken()}` },
          });
          return next.handle(clonedRequest);
        }),
      );
    } else {
      // If refreshing the token fails or for any other error, we throw the error
      return throwError(error);
    }
  }

  /**
   * Sets the basic/oauth authorization header depending on the configuration.
   * @param {string} authenticationKey Authentication key.
   */
  setAuthorizationToken(authenticationKey: string) {
    if (environment.oauth.enabled) {
      httpOptions.headers[authorizationHeader] = `Bearer ${authenticationKey}`;
    } else {
      httpOptions.headers[authorizationHeader] = `Basic ${authenticationKey}`;
    }
  }

  setJwtAuthToken(authenticationKey: string) {
    httpOptions.headers[authorizationHeader] = `Bearer ${authenticationKey}`;
  }

  /**
   * Sets the two factor access token header.
   * @param {string} twoFactorAccessToken Two factor access token.
   */
  setTwoFactorAccessToken(twoFactorAccessToken: string) {
    httpOptions.headers[twoFactorAccessTokenHeader] = twoFactorAccessToken;
  }

  /**
   * Removes the authorization header.
   */
  removeAuthorization() {
    delete httpOptions.headers[authorizationHeader];
  }

  /**
   * Removes the two factor access token header.
   */
  removeTwoFactorAuthorization() {
    delete httpOptions.headers[twoFactorAccessTokenHeader];
  }

  refreshToken(): Observable<void> {
    const keyClockInstance = this.keycloakService.getKeycloakInstance();
    return from(
      new Promise<void>((resolve, reject) => {
        if (!keyClockInstance) {
          reject('Keycloak instance is not available');
          return;
        }
        keyClockInstance.updateToken(5)
          .then(refreshed => {
            if (refreshed) {
              console.log('Token was successfully refreshed');
              resolve();
            } else {
              console.warn('Token is still valid');
              resolve();
            }
          })
          .catch(err => {
            console.error('Failed to refresh the token', err);
            reject(err);
          });
      })
    );
  }

  getAccessToken(): string {
    const keyClockInstance = this.keycloakService.getKeycloakInstance();
    return keyClockInstance.token;
  }
}
