import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DeleteDialogComponent } from '../../delete-dialog/delete-dialog.component';
import { DateFormatPipe } from '../../../pipes/date-format.pipe';
import { FormDialogComponent } from '../../form-dialog/form-dialog.component';

@Component({
  selector: 'mifosx-entity-messages-tab',
  templateUrl: './entity-messages-tab.component.html',
  styleUrls: ['./entity-messages-tab.component.scss']
})
export class EntityMessagesTabComponent implements OnInit {

  @ViewChild('formRef', { static: true }) formRef: any;

  @ViewChild('uploadFile') attachment: any;

  @Input() entityId: string;
  @Input() messagesList: any;
  @Input() currentPage: number;
  @Input() itemsPerPage: number;
  @Input() totalElements: number;
  @Input() searchResultFlag: boolean;
  @Output() callbackSend = new EventEmitter<{}>();
  @Output() callbackDelete = new EventEmitter<{ messageId: number, index: number }>();
  @Output() callbackEdit = new EventEmitter<{ messageId: number, messageText: string, index: number }>();
  @Output() callbackDownload = new EventEmitter<number>();
  @Output() callbackChangePage = new EventEmitter<{ pageIndex: number, pageSize: number }>();
  messageForm: FormGroup;
  fileList: File[] = [];
  fileNameList: string[] = [];

  constructor(private formBuilder: FormBuilder,
              private dialog: MatDialog,
              private datePipe: DateFormatPipe
  ) {
  }

  ngOnInit() {
    this.createMessageForm();
  }

  createMessageForm() {
    this.messageForm = this.formBuilder.group({
      'message': ['', Validators.required],
      'attachment': []
    });
  }

  onFileChanged(event: any) {
    for (let i = 0; i <= event.target.files.length - 1; i++) {
      const selectedFile = event.target.files[i];
      this.fileList.push(selectedFile);
      this.fileNameList.push(selectedFile.name);
    }

    this.attachment.nativeElement.value = '';
  }

  removeSelectedFile(index: any) {
    this.fileNameList.splice(index, 1);
    this.fileList.splice(index, 1);
  }

  sendMessage() {
    if (this.fileList.length > 0) {
      this.messageForm.patchValue({
        attachment: this.fileList
      });
    }
    this.callbackSend.emit(this.messageForm.value);
    this.fileNameList = [];
    this.fileList = [];
    this.formRef.resetForm();
  }

  onPageChange($event: any) {
    this.callbackChangePage.emit({ pageIndex: $event.pageIndex, pageSize: $event.pageSize });
  }

  onDownload(documentId: number) {
    this.callbackDownload.emit(documentId);
  }

  editMessage(messageId: number, textMessage: string, index: number) {
    const editMessageDialogRef = this.dialog.open(FormDialogComponent, {
      data: {
        formfields: [{
          controlName: 'message',
          required: true,
          value: textMessage,
          controlType: 'input',
          label: 'Message'
        }],
        layout: {
          columns: 1,
          addButtonText: 'Confirm'
        },
        title: 'Edit Message'
      }
    });
    editMessageDialogRef.afterClosed().subscribe((response: any) => {
      if (response.data && response.data.value.message !== textMessage) {
        this.callbackEdit.emit({ messageId: messageId, messageText: response.data.value.message, index });
      }
    });
  }

  deleteMessage(messageId: number, index: number) {
    const deleteMessageDialogRef = this.dialog.open(DeleteDialogComponent, {
      data: { deleteContext: `the '${this.messagesList[index].message}' message sent on ${this.datePipe.transform(this.messagesList[index].createdDate)}` }
    });
    deleteMessageDialogRef.afterClosed().subscribe((response: any) => {
      if (response.delete) {
        this.callbackDelete.emit({ messageId, index });
      }
    });
  }
}
