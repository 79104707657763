<div class="container">

  <div #filter fxLayout="row">
    <mat-form-field fxFlex>
      <mat-label>{{ 'labels.inputs.Filter' | translate }}</mat-label>
      <input matInput (keyup)="applyFilter($event.target.value)">
    </mat-form-field>
  </div>

  <div #configurationsTable class="mat-elevation-z8">

    <table mat-table [dataSource]="dataSource" matSort>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
        <td mat-cell *matCellDef="let configuration"> {{ configuration.name }}&nbsp;&nbsp;
        <fa-icon icon="question-circle" matTooltip="{{ configuration.description === undefined ? 'No Description' : configuration.description }}" matTooltipPosition="right"></fa-icon>
        </td>
      </ng-container>

      <ng-container matColumnDef="enabled">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
        <td mat-cell *matCellDef="let configuration">
          <mat-slide-toggle [(ngModel)]="configuration.enabled" name="{{configuration.name}}" (click)="toggleStatus(configuration)">
            <div [className]="configuration.enabled ? 'enabled' : 'disabled'">
              <span *ngIf="configuration.enabled">Enabled</span>
              <span *ngIf="!configuration.enabled">Disabled</span>
            </div>
          </mat-slide-toggle>
        </td>
      </ng-container>

      <ng-container matColumnDef="value">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Value </th>
        <td mat-cell *matCellDef="let configuration"> {{ configuration.value }}</td>
      </ng-container>

      <ng-container matColumnDef="stringValue">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> String Value </th>
        <td mat-cell *matCellDef="let configuration"> {{ configuration.stringValue }}</td>
      </ng-container>

      <ng-container matColumnDef="dateValue">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Date Value </th>
        <td mat-cell *matCellDef="let configuration"> {{ configuration.dateValue | dateFormat }}</td>
      </ng-container>

      <ng-container matColumnDef="edit">
        <th mat-header-cell *matHeaderCellDef> Edit </th>
        <td mat-cell *matCellDef="let configuration">
          <button mat-button color="primary" [routerLink]="[configuration.id, 'edit']">
            <fa-icon icon="edit"></fa-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    </table>

    <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons></mat-paginator>

  </div>

</div>

<ng-template #templateFilter let-data let-popover="popover">
 <h4>Search bar to filter configurations.</h4>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn" (click)="popover.close();configurationWizardService.closeConfigWizard()">Close</button>
    <button mat-raised-button color="primary" (click)="popover.close();previousStep()">Back</button>
    <button mat-raised-button color="primary" (click)="popover.close();showPopover(templateConfigurationsTable, configurationsTable, 'top', true)">Next</button>
  </div>
</ng-template>

<ng-template #templateConfigurationsTable let-data let-popover="popover">
  <h4 class="mw300">Global Configuration options and their current status. For more details click: <a href="https://mifosforge.jira.com/wiki/spaces/docs/pages/67895362/Global+Configuration" target="_blank">Global Configurations</a></h4>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn" (click)="popover.close();configurationWizardService.closeConfigWizard()">Close</button>
    <button mat-raised-button color="primary" (click)="popover.close();showPopover(templateFilter, filter, 'bottom', true)">Back</button>
    <button mat-raised-button color="primary" (click)="popover.close();nextStep()">Next</button>
  </div>
</ng-template>
