import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'mifosx-entity-messages-header',
  templateUrl: './entity-messages-header.component.html',
  styleUrls: ['./entity-messages-header.component.scss']
})
export class EntityMessagesHeaderComponent implements OnInit {
  searchForm: FormGroup;
  @Input() searchResultFlag: boolean;
  @Output() callbackSearch = new EventEmitter<string>();
  @Output() callbackResetSearch = new EventEmitter<{}>();

  constructor(private formBuilder: FormBuilder) {
  }

  ngOnInit(): void {
    this.createSearchForm();
    this.searchForm.get('search').valueChanges.subscribe(value => {
      if (!value && this.searchResultFlag) {
        this.callbackResetSearch.emit();
      }
    });
  }

  createSearchForm() {
    this.searchForm = this.formBuilder.group({
      'search': []
    });
  }

  onEnter() {
    this.callbackSearch.emit(this.searchForm.value);
  }
}
