<div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column" class="container m-b-20">
  <button mat-raised-button color="primary" [routerLink]="['edit']" *mifosxHasPermission="'UPDATE_FUND'">
    <fa-icon icon="edit" class="m-r-10"></fa-icon>
    Edit
  </button>
</div>

<div class="container">

  <mat-card>

    <mat-card-content>

      <div fxLayout="row wrap" class="content">

        <div fxFlex="50%" class="mat-body-strong">
          Name
        </div>

        <div fxFlex="50%">
          {{ fundData.name }}
        </div>

        <div fxFlex="50%" class="mat-body-strong">
          External Id
        </div>

        <div fxFlex="50%">
          {{ fundData.externalId }}
        </div>

      </div>

      <div fxLayout="row" fxLayoutAlign="center" fxLayoutGap="2%" fxLayout.lt-md="column">
        <button type="button" mat-raised-button color="primary" [routerLink]="['../']">{{ 'Back' | translate }}</button>
      </div>
    </mat-card-content>

  </mat-card>

</div>
