<div class="container">

  <mat-card>

    <div fxLayout="row" fxLayout.lt-md="column">

      <div fxFlex="50%">

        <mat-nav-list>

          <mat-list-item *mifosxHasPermission="'CREATE_JOURNALENTRY'" [routerLink]="['journal-entries/frequent-postings']">
            <mat-icon matListIcon>
              <fa-icon icon="sync" size="sm"></fa-icon>
            </mat-icon>
            <h4 matLine>Frequent Postings</h4>
            <p matLine>These are predefined postings</p>
          </mat-list-item>

          <div #createJournalEntries>
            <mat-list-item *mifosxHasPermission="'CREATE_JOURNALENTRY'" [routerLink]="['journal-entries/create']">
              <mat-icon matListIcon>
                <fa-icon icon="plus" size="sm"></fa-icon>
              </mat-icon>
              <h4 matLine>Create Journal Entries</h4>
              <p matLine>Manual journal entry transactions recorded in a journal</p>
            </mat-list-item>
          </div>

          <mat-list-item *mifosxHasPermission="'READ_JOURNALENTRY'" [routerLink]="['journal-entries']">
            <mat-icon matListIcon>
              <fa-icon icon="search" size="sm"></fa-icon>
            </mat-icon>
            <h4 matLine>Search Journal Entries</h4>
            <p matLine>Advanced search option for journal entries</p>
          </mat-list-item>

          <div #accountsLinked>
            <mat-list-item *mifosxHasPermission="'READ_FINANCIALACTIVITYACCOUNT'" [routerLink]="['financial-activity-mappings']">
              <mat-icon matListIcon>
                <fa-icon icon="link" size="sm"></fa-icon>
              </mat-icon>
              <h4 matLine>Accounts Linked to Financial Activities</h4>
              <p matLine>List of Financial Activities and GL Account Mappings</p>
            </mat-list-item>
          </div>

          <div #migrateOpeningBalances>
            <mat-list-item *mifosxHasPermission="'READ_JOURNALENTRY'" [routerLink]="['migrate-opening-balances']">
              <mat-icon matListIcon>
                <fa-icon icon="hand-holding-usd" size="sm"></fa-icon>
              </mat-icon>
              <h4 matLine>Migrate Opening Balances (Office-wise)</h4>
              <p matLine>Set or update office-level opening balances for GL accounts</p>
            </mat-list-item>
          </div>

        </mat-nav-list>

      </div>

      <div fxFlex="50%">

        <mat-nav-list>

          <div #chartofAccounts>
            <mat-list-item *mifosxHasPermission="'READ_GLACCOUNT'" [routerLink]="['chart-of-accounts']">
              <mat-icon matListIcon>
                <fa-icon icon="sitemap" size="sm"></fa-icon>
              </mat-icon>
              <h4 matLine>Chart of Accounts</h4>
              <p matLine>List of accounts used by the organization</p>
            </mat-list-item>
          </div>

          <div #closingEntries>
            <mat-list-item *mifosxHasPermission="'READ_GLCLOSURE'" [routerLink]="['closing-entries']">
              <mat-icon matListIcon>
                <fa-icon icon="archive" size="sm"></fa-icon>
              </mat-icon>
              <h4 matLine>Closing Entries</h4>
              <p matLine>Journal entries made at the end of accounting period</p>
            </mat-list-item>
          </div>

          <mat-list-item *mifosxHasPermission="'READ_ACCOUNTINGRULE'" [routerLink]="['accounting-rules']">
            <mat-icon matListIcon>
              <fa-icon icon="list" size="sm"></fa-icon>
            </mat-icon>
            <h4 matLine>Accounting Rules</h4>
            <p matLine>List of all accounting rules</p>
          </mat-list-item>

          <mat-list-item *mifosxHasPermission="'EXECUTE_PERIODICACCRUALACCOUNTING'" [routerLink]="['periodic-accruals']">
            <mat-icon matListIcon>
              <fa-icon icon="calendar" size="sm"></fa-icon>
            </mat-icon>
            <h4 matLine>Accruals</h4>
            <p matLine>Accrues income, expenses and liabilities as on the provided date</p>
          </mat-list-item>

          <mat-list-item *mifosxHasPermission="'VIEW_PROVISIONING_ENTRIES'" [routerLink]="['provisioning-entries']">
            <mat-icon matListIcon>
              <fa-icon icon="cogs" size="sm"></fa-icon>
            </mat-icon>
            <h4 matLine>Provisioning Entries</h4>
            <p matLine>Create provisioning entries</p>
          </mat-list-item>

        </mat-nav-list>

      </div>

    </div>

  </mat-card>

</div>

<ng-template #templateChartofAccounts let-popover="popover">
  <h2>Chart of Accounts</h2>
  <p class="mw300">The Chart of Accounts - General Ledger Setup allows you to organize your different accounts within Mifos X.</p>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn" (click)="popover.close();configurationWizardService.closeConfigWizard()">Close</button>
    <button mat-raised-button color="primary" (click)="popover.close();previousStepChartofAccounts()">Back</button>
    <button mat-raised-button color="primary" (click)="popover.close();nextStepChartofAccounts()">Next</button>
  </div>
</ng-template>

<ng-template #templateAccountsLinked let-popover="popover">
  <h2>Accounts linked to Financial Activities</h2>
  <p class="mw400">Accounts linked to Financial Activities are mapped accounts which are needed to do system generating accounting transaction.</p>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn" (click)="popover.close();configurationWizardService.closeConfigWizard()">Close</button>
    <button mat-raised-button color="primary" (click)="popover.close();previousStepAccountsLinked()">Back</button>
    <button mat-raised-button color="primary" (click)="popover.close();nextStepAccountsLinked()">Next</button>
  </div>
</ng-template>

<ng-template #templateMigrateOpeningBalances let-popover="popover">
  <h2>Migrate opening balances (Office-wise)</h2>
  <p class="mw400">This makes it easier for an organization wanting to move to Mifos to transfer GL account balances (from their existing accounting application or manual system) to Mifos X Accounting.</p>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn" (click)="popover.close();configurationWizardService.closeConfigWizard()">Close</button>
    <button mat-raised-button color="primary" (click)="popover.close();previousStepMigrateOpeningBalances()">Back</button>
    <button mat-raised-button color="primary" (click)="popover.close();nextStepMigrateOpeningBalances()">Next</button>
  </div>
</ng-template>

<ng-template #templateClosingEntries let-popover="popover">
  <h2>Closing Entries</h2>
  <p class="mw300">In Mifos X, you can close accounting to journal entries. Closures can be applied to all branches or to each branch separately. It is important for organizations to close accounting periodically to prevent transactions that affect the accounting books.</p>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn" (click)="popover.close();configurationWizardService.closeConfigWizard()">Close</button>
    <button mat-raised-button color="primary" (click)="popover.close();previousStepClosingEntries()">Back</button>
    <button mat-raised-button color="primary" (click)="popover.close();nextStepClosingEntries()">Next</button>
  </div>
</ng-template>

<ng-template #templateCreateJournalEntries let-popover="popover">
  <h2>Create Journal Entries</h2>
  <p class="mw300">Journal Entries are essentially logs of all the transactions in your organization that have accounting implications.</p>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn" (click)="popover.close();configurationWizardService.closeConfigWizard()">Close</button>
    <button mat-raised-button color="primary" (click)="popover.close();previousStepCreateJournalEntries()">Back</button>
    <button mat-raised-button color="primary" (click)="popover.close();nextStepCreateJournalEntries()">Next</button>
  </div>
</ng-template>
