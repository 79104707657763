<div class="container">

  <mat-card>

    <form [formGroup]="floatingRateForm" (ngSubmit)="submit()">

      <mat-card-content>

        <div fxLayout="row wrap" fxLayout.lt-md="column" fxLayoutGap="15%">

          <mat-form-field fxFlex="25%">
            <mat-label>Floating Rate Name</mat-label>
            <input matInput required matTooltip="Unique name for the floating rate scheme." formControlName="name">
            <mat-error *ngIf="floatingRateForm.controls.name.hasError('required')">
              Floating Rate Name is <strong>required</strong>
            </mat-error>
          </mat-form-field>

          <div class="checkbox-wrapper" fxFlex="25%">
            <mat-checkbox formControlName="isBaseLendingRate" matTooltip="Check if this floating rate scheme is to be considered base lending rate for the organisation" class="checkbox" labelPosition="before">
              Is Base Lending Rate?
            </mat-checkbox>
          </div>

          <div class="checkbox-wrapper" fxFlex="15%">
            <mat-checkbox formControlName="isActive" matTooltip="Check if this floating rate scheme to be activated" class="checkbox" labelPosition="before">
              Active
            </mat-checkbox>
          </div>

        </div>

        <br>

        <mat-divider [inset]="true"></mat-divider>

        <br>

        <div fxLayout="row wrap" fxLayoutGap="70%" fxLayoutGap.lt-md="0px" class="m-b-10" fxLayout.lt-md="column">

          <p fxFlex="25%" class="mat-title">Floating Rate Periods <i class="fas fa-question" matTooltip="Floating interest rate and start date for this floating rate scheme"></i></p>

          <button mat-mini-fab type="button" color="primary" (click)="addFloatingRatePeriod()">
            <fa-icon icon="plus"></fa-icon>
          </button>

        </div>

        <table mat-table [dataSource]="dataSource" matSort>

          <ng-container matColumnDef="fromDate">
            <th mat-header-cell *matHeaderCellDef matTooltip="Interest effective from date" mat-sort-header> From Date </th>
            <td mat-cell *matCellDef="let floatingRatePeriod"> {{ floatingRatePeriod.fromDate  | dateFormat : 'dd MMMM yyyy' }} </td>
          </ng-container>

          <ng-container matColumnDef="interestRate">
            <th mat-header-cell *matHeaderCellDef matTooltip="Annualised Interest rate" mat-sort-header> Interest Rate </th>
            <td mat-cell *matCellDef="let floatingRatePeriod"> {{ floatingRatePeriod.interestRate }} </td>
          </ng-container>

          <ng-container matColumnDef="isDifferential">
            <th mat-header-cell *matHeaderCellDef matTooltip="If checked, Interest rate mentioned is added to base lending rate as of the from date of this period, absolute otherwise" mat-sort-header> Is Differential? </th>
            <td mat-cell *matCellDef="let floatingRate">
              <fa-icon *ngIf="floatingRate.isDifferentialToBaseLendingRate" icon="check-circle" size="lg"
                class="is-differential" matTooltip="Yes" matTooltipPosition="right"></fa-icon>
              <fa-icon *ngIf="!floatingRate.isDifferentialToBaseLendingRate" icon="times-circle" size="lg"
                class="not-is-differential" matTooltip="No" matTooltipPosition="right"></fa-icon>
            </td>
          </ng-container>

          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef> Actions </th>
            <td mat-cell *matCellDef="let floatingRatePeriod">
              <button type="button" color="primary" mat-icon-button
                (click)="editFloatingRatePeriod(floatingRatePeriod)">
                <fa-icon icon="edit" size="lg"></fa-icon>
              </button>
              <button type="button" color="warn" mat-icon-button
                (click)="deleteFloatingRatePeriod(floatingRatePeriod)">
                <fa-icon icon="trash" size="lg"></fa-icon>
              </button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        </table>

        <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons></mat-paginator>

      </mat-card-content>

      <mat-card-actions fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="5px">
        <button type="button" mat-raised-button [routerLink]="['../']">Cancel</button>
        <button mat-raised-button type="submit" color="primary" [disabled]="!floatingRateForm.valid || floatingRateForm.pristine && isFloatingRateFormPristine">
          Submit
        </button>
      </mat-card-actions>

    </form>

  </mat-card>

</div>
