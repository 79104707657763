/** Angular Imports */
import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';

/** Custom Services */
import { Logger } from '../logger/logger.service';
import { AuthenticationService } from './authentication.service';
import { HttpErrorResponse } from '@angular/common/http';
import { catchError, filter } from 'rxjs/operators';
import { AuthenticationInterceptor } from './authentication.interceptor';

/** Initialize logger */
const log = new Logger('AuthenticationGuard');

/**
 * Route access authorization.
 */
@Injectable()
export class AuthenticationGuard implements CanActivate {
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private keycloakService: KeycloakService,
    private authenticationInterceptor: AuthenticationInterceptor,
  ) {}

  /**
   * Ensures route access is authorized only when user is authenticated, otherwise redirects to login.
   *
   * @returns {boolean} True if user is authenticated.
   */
  canActivate(): boolean {
    if (this.authenticationService.isAuthenticated()) {
      return true;
    }

    log.debug('User not authenticated, redirecting to login...');
    this.authenticationService.logout();
    this.keycloakService.getToken().then((token) => {
      if (token) {
        this.authenticationService
          .getUserDetails(token)
          .pipe(
            filter((x) => !!x),
            catchError((error: HttpErrorResponse) => {
              throw error;
            }),
          )
          .subscribe((res: any) => {
            this.authenticationService.setCredentials({
              ...res,
              base64EncodedAuthenticationKey: token,
              authenticated: true,
              username: '',
            });
            this.authenticationInterceptor.setJwtAuthToken(token);
            this.router.navigate(['/home'], { replaceUrl: true });
          });
      }
    });
    return false;
  }
}
