<form [formGroup]="savingProductTermsForm">

  <div fxLayout="row wrap" fxLayoutGap="2%" fxLayout.lt-md="column">

    <mat-form-field fxFlex="48%">
      <mat-label>Nominal Annual Interest Rate</mat-label>
      <input type="number" matInput matTooltip="The default interest rate set when creating savings accounts" formControlName="nominalAnnualInterestRate" required>
      <mat-error>
        Nominal Annual Interest is <strong>required</strong>
      </mat-error>
    </mat-form-field>

    <span fxFlex="48%" fxHide.lt-md></span>

    <mat-form-field fxFlex="48%">
      <mat-label>Interest Compounding Period</mat-label>
      <mat-select formControlName="interestCompoundingPeriodType" matTooltip="The period at which interest rate is compounded" required>
        <mat-option *ngFor="let interestCompoundingPeriodType of interestCompoundingPeriodTypeData" [value]="interestCompoundingPeriodType.id">
          {{ interestCompoundingPeriodType.value }}
        </mat-option>
      </mat-select>
      <mat-error>
        Interest Compounding Period is <strong>required</strong>
      </mat-error>
    </mat-form-field>

    <mat-form-field fxFlex="48%">
      <mat-label>Interest Posting Period</mat-label>
      <mat-select formControlName="interestPostingPeriodType" matTooltip="The period at which interest rate is posted or credited to a saving account" required>
        <mat-option *ngFor="let interestPostingPeriodType of interestPostingPeriodTypeData" [value]="interestPostingPeriodType.id">
          {{ interestPostingPeriodType.value }}
        </mat-option>
      </mat-select>
      <mat-error>
        Interest Posting Period is <strong>required</strong>
      </mat-error>
    </mat-form-field>

    <mat-form-field fxFlex="48%">
      <mat-label>Interest Calculated using</mat-label>
      <mat-select formControlName="interestCalculationType" matTooltip="The method used to calculate interest" required>
        <mat-option *ngFor="let interestCalculationType of interestCalculationTypeData" [value]="interestCalculationType.id">
          {{ interestCalculationType.value }}
        </mat-option>
      </mat-select>
      <mat-error>
        Interest Calculated using is <strong>required</strong>
      </mat-error>
    </mat-form-field>

    <mat-form-field fxFlex="48%">
      <mat-label>Days in Year</mat-label>
      <mat-select formControlName="interestCalculationDaysInYearType" matTooltip="The setting for number of days in year to use to calculate interest" required>
        <mat-option *ngFor="let interestCalculationDaysInYearType of interestCalculationDaysInYearTypeData" [value]="interestCalculationDaysInYearType.id">
          {{ interestCalculationDaysInYearType.value }}
        </mat-option>
      </mat-select>
      <mat-error>
        Days in Year is <strong>required</strong>
      </mat-error>
    </mat-form-field>

  </div>

  <div fxLayout="row" class="margin-t" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="2%">
    <button mat-raised-button matStepperPrevious>
      <fa-icon icon="arrow-left" class="m-r-10"></fa-icon>
      Previous
    </button>
    <button mat-raised-button matStepperNext>
      Next
      <fa-icon icon="arrow-right" class="m-l-10"></fa-icon>
    </button>
  </div>

</form>
