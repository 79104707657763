/** Angular Imports */
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';

/** RxJS Imports */
import { forkJoin } from 'rxjs';

/** Custom Services */
import { NotificationsService } from 'app/notifications/notifications.service';

/**
 * Notifications Tray Component
 */
@Component({
  selector: 'mifosx-notifications-tray',
  templateUrl: './notifications-tray.component.html',
  styleUrls: ['./notifications-tray.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NotificationsTrayComponent implements OnInit, OnDestroy {
  /** Read Notifications */
  readNotifications: any[] = [];
  /** Displayed Read Notifications */
  displayedReadNotifications: any[] = [];
  /** Unread Notifications */
  unreadNotifications: any[] = [];
  /** Timer to refetch notifications every 60 seconds */
  timer: any;

  /**
   * Gets router link prefix from notification's objectType attribute
   * Shares, Savings, Deposits, Loans routes inaccessible because of dependency on entity ID.
   */
  routeMap: any = {
    'client': '/clients/',
    'group': '/groups/',
    'loan': '/loans-accounts/',
    'chat': '/loans-accounts/',
    'center': '/centers/',
    'shareAccount': '/shares-accounts/',
    'fixedDeposit': '/fixed-deposits-accounts/',
    'recurringDepositAccount': '/recurringdeposits/',
    'savingsAccount': '/savings-accounts/',
    'shareProduct': '/products/share-products/',
    'loanProduct': '/products/loan-products/'
  };

  /**
   * @param {NotificationsService} notificationsService Notifications Service
   */
  constructor(public notificationsService: NotificationsService) {
    forkJoin([this.notificationsService.getNotifications(true), this.notificationsService.getNotifications(false)])
      .subscribe((response: any[]) => {
        this.unreadNotifications = response[1].pageItems;
        this.readNotifications = this.removeCommonElements(this.unreadNotifications, response[0].pageItems);
        this.setNotifications();
      });
  }

  removeCommonElements(list1: any[], list2: any[]) {
    list2 = list2.filter(item2 => {
      return !list1.some(item1 => item1.id === item2.id);
    });
    return list2;
  }

  ngOnInit() {
    setTimeout(() => {
      this.fetchUnreadNotifications();
    }, 60000);
  }

  ngOnDestroy() {
    clearTimeout(this.timer);
  }

  /**
   * Restructures displayed read notifications vis-a-vis unread notifications.
   */
  setNotifications() {
    const length = this.unreadNotifications.length;
    this.displayedReadNotifications = length < 10 ? this.readNotifications.slice(0, 10 - length) : [];
  }

  /**
   * Recursively fetch unread notifications.
   */
  fetchUnreadNotifications() {
    this.notificationsService.getNotifications(false).subscribe((response: any) => {
      this.unreadNotifications = response.pageItems;
      this.setNotifications();
    });
    this.timer = setTimeout(() => {
      this.fetchUnreadNotifications();
    }, 60000);
  }

  /**
   * Update read/unread notifications.
   */
  menuClosed() {
    this.notificationsService.updateNotifications().subscribe(() => {
    });
    this.readNotifications = this.unreadNotifications.concat(this.readNotifications);
    this.unreadNotifications = [];
    this.setNotifications();
  }

  get displayCount(): string {
    return this.unreadNotifications.length > 99 ? '99+' : this.unreadNotifications.length.toString();
  }


}
