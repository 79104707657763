import { Component, Input } from '@angular/core';
import { NotificationStatus, NotificationType } from '../../notifications/constants';

@Component({
  selector: 'mifosx-notification-item',
  templateUrl: './notification-item.component.html',
  styleUrls: ['./notification-item.component.scss']
})
export class NotificationItemComponent {

  @Input() createdAt: Date;
  @Input() content: string;
  @Input() objectType: any;
  @Input() objectId: any;
  @Input() icon: any;
  @Input() status: string;
  @Input() isRead: boolean;

  getStatusColor(status: string) {
    switch (status) {
      case NotificationStatus.APPROVED:
        return '#009145';
      case NotificationStatus.CLOSED:
        return '#009145';
      case NotificationStatus.LOAN_RESCHEDULED:
        return '#F6921E';
      case NotificationStatus.REPAYMENT_MADE:
        return '#F6921E';
      case NotificationStatus.CREATED:
        return '#009145';
      case NotificationStatus.CONTRACT_SIGNED:
        return '#009145';
      case NotificationStatus.CONTRACT_SIGNING_REQUIRED:
        return '#E14443';
      case NotificationStatus.DISBURSED:
        return '#009145';
      case NotificationStatus.PAYMENT_DUE:
        return '#F6921E';
      default:
        return '#282C38';
    }
  }

  getStatusText(status: string) {
    switch (status) {
      case NotificationStatus.APPROVED:
        return 'approved';
      case NotificationStatus.CLOSED:
        return 'closed';
      case NotificationStatus.LOAN_RESCHEDULED:
        return 'loan rescheduled';
      case NotificationStatus.REPAYMENT_MADE:
        return 'repayment made';
      case NotificationStatus.CREATED:
        return 'created';
      case NotificationStatus.CONTRACT_SIGNED:
        return 'contract signed';
      case NotificationStatus.CONTRACT_SIGNING_REQUIRED:
        return 'contract signing required';
      case NotificationStatus.DISBURSED:
        return 'disbursed';
      case NotificationStatus.PAYMENT_DUE:
        return 'payment due';
      default:
        return status;
    }
  }

  getNotificationText(type: NotificationType) {
    switch (type) {
      case NotificationType.LOAN:
        return 'Loan status is ';
      case NotificationType.LOAN_PRODUCT:
        return 'Loan product status is';
      case NotificationType.CLIENT:
        return 'client';
      case NotificationType.CHAT:
        return `You received a ${this.content[0].toLowerCase() + this.content.slice(1)}`;
      default:
        return this.content;
    }
  }

}
