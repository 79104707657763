<div class="mat-typography">

    <h3>Family Members</h3>

    <div fxLayout="row" fxLayoutAlign="flex-end" class="m-b-20">
      <button mat-raised-button color="primary" (click)="addFamilyMember()">
        <fa-icon icon="plus" class="m-r-10"></fa-icon>Add
      </button>
    </div>

    <mat-accordion>

      <mat-expansion-panel *ngFor="let member of clientFamilyMembers;index as i" class="family-member">

        <mat-expansion-panel-header>

          <mat-panel-title>
            {{ member.firstName }} {{ member.middleName ? member.middleName + ' ' + member.lastName : member.lastName }}
          </mat-panel-title>

          <mat-panel-description>
            {{member.relationship}}
          </mat-panel-description>

        </mat-expansion-panel-header>

        <mat-divider></mat-divider>

        <div class="family-member-actions" fxLayout="row" fxLayoutAlign="flex-end">
          <button mat-button color="primary">
            <fa-icon icon="edit" (click)="editFamilyMember(member, i)"></fa-icon>
          </button>
          <button mat-button color="warn" (click)="deleteFamilyMember(member.firstName,i)">
            <fa-icon icon="trash"></fa-icon>
          </button>
        </div>

        <p>
          First Name : {{ member.firstName }}<br />
          Middle Name : {{ member.middleName }}<br />
          Last Name : {{ member.lastName }}<br />
          Qualification : {{ member.qualification }}<br />
          Relationship : {{ member.relationshipId | find:clientTemplate.familyMemberOptions.relationshipIdOptions:'id':'name' }}<br />
          Age : {{ member.age }}<br />
          Is Dependent : {{ member.isDependent ? 'Yes' : 'No' }}<br />
          Marital Status : {{ member.maritalStatusId | find:clientTemplate.familyMemberOptions.maritalStatusIdOptions:'id':'name' }}<br />
          Gender : {{ member.genderId | find:clientTemplate.familyMemberOptions.genderIdOptions:'id':'name' }}<br />
          Profession : {{member.professionId | find:clientTemplate.familyMemberOptions.professionIdOptions:'id':'name' }}<br />
          Date Of Birth : {{member.dateOfBirth  | dateFormat}}<br />
        </p>

      </mat-expansion-panel>

    </mat-accordion>

    <div fxLayout="row" class="margin-t" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="2%">
      <button mat-raised-button matStepperPrevious>
        <fa-icon icon="arrow-left" class="m-r-10"></fa-icon>
        Previous
      </button>
      <button mat-raised-button matStepperNext>
        Next
        <fa-icon icon="arrow-right" class="m-l-10"></fa-icon>
      </button>
    </div>

</div>
