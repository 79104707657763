<form [formGroup]="shareProductSettingsForm">

  <div fxLayout="row wrap" fxLayoutGap="2%" fxLayout.lt-md="column">

    <h4 fxFlex="98%" class="mat-h4">Shares per Client <i class="fas fa-question" matTooltip="These fields are used to define the minimum, default, maximum shares per customer"></i></h4>

    <mat-form-field fxFlex="31%">
      <mat-label>Minimum</mat-label>
      <input type="number" matInput formControlName="minimumShares">
    </mat-form-field>

    <mat-form-field fxFlex="31%">
      <mat-label>Default</mat-label>
      <input type="number" matInput formControlName="nominalShares" required>
      <mat-error>
        Default Shares per Client is <strong>required</strong>
      </mat-error>
    </mat-form-field>

    <mat-form-field fxFlex="31%">
      <mat-label>Maximum</mat-label>
      <input type="number" matInput formControlName="maximumShares">
    </mat-form-field>

    <h4 fxFlex="98%" class="mat-h4">Minimum Active Period <i class="fas fa-question" matTooltip="Minimum active period to consider for dividend calculations"></i></h4>

    <mat-form-field fxFlex="48%">
      <mat-label>Frequency</mat-label>
      <input type="number" matInput formControlName="minimumActivePeriodForDividends">
    </mat-form-field>

    <mat-form-field fxFlex="48%">
      <mat-label>Type</mat-label>
      <mat-select formControlName="minimumactiveperiodFrequencyType">
        <mat-option *ngFor="let minimumActivePeriodFrequencyType of minimumActivePeriodFrequencyTypeData" [value]="minimumActivePeriodFrequencyType.id">
          {{ minimumActivePeriodFrequencyType.value }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <h4 fxFlex="98%" class="mat-h4">Lock-in Period <i class="fas fa-question" matTooltip="Used to indicate the length of time that a share account of this share product type is locked-in and redeems are not allowed"></i></h4>

    <mat-form-field fxFlex="48%">
      <mat-label>Frequency</mat-label>
      <input type="number" matInput formControlName="lockinPeriodFrequency">
    </mat-form-field>

    <mat-form-field fxFlex="48%">
      <mat-label>Type</mat-label>
      <mat-select formControlName="lockinPeriodFrequencyType">
        <mat-option *ngFor="let lockinPeriodFrequencyType of lockinPeriodFrequencyTypeData" [value]="lockinPeriodFrequencyType.id">
          {{ lockinPeriodFrequencyType.value }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-checkbox fxFlex="98%" class="margin-v" labelPosition="before" matTooltip="Indicates whether the dividends can be processed even share account of this share product is inactive" formControlName="allowDividendCalculationForInactiveClients">
      Allow dividends for inactive clients
    </mat-checkbox>

  </div>

  <div fxLayout="row" class="margin-t" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="2%">
    <button mat-raised-button matStepperPrevious>
      <fa-icon icon="arrow-left" class="m-r-10"></fa-icon>
      Previous
    </button>
    <button mat-raised-button matStepperNext>
      Next
      <fa-icon icon="arrow-right" class="m-l-10"></fa-icon>
    </button>
  </div>

</form>
