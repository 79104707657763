<mat-card class="container">
  <div fxLayout="row" fxLayoutAlign="start center">
    <div class="search-box m-r-30">
      <mat-form-field class="search-box">
        <input matInput placeholder="Search by client name, external Id, mobile" class="search-box" (keydown.enter)="search($event.target.value)">
      </mat-form-field>
    </div>
    <div class="filter-box" *ngIf="existsClientsToFilter">
      <mat-checkbox #showClosedAccounts labelPosition="after" class="m-r-10">Show Closed Accounts
      </mat-checkbox>
    </div>
    <div class="action-button" fxLayoutGap="25px">
      <button mat-raised-button color="primary" [routerLink]="['../', 'organization', 'bulk-import', 'Clients']" *mifosxHasPermission="'CREATE_CLIENT'">
        <fa-icon icon="download" class="m-r-10"></fa-icon>Import Client
      </button>
      <button mat-raised-button color="primary" [routerLink]="['create']" *mifosxHasPermission="'CREATE_CLIENT'">
        <fa-icon icon="plus" class="m-r-10"></fa-icon>Create Client
      </button>
    </div>
  </div>
<!-- <div [hidden]="!existsClientsToFilter">-->
  <div [hidden]="notExistsClientsToFilter">
    <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
    <table mat-table [dataSource]="dataSource" matSort (matSortChange)="sortChanged($event)">

      <!-- Name Column -->
      <ng-container matColumnDef="displayName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
        <td mat-cell *matCellDef="let row"> {{row.displayName}} </td>
      </ng-container>

      <!-- Account no Column -->
      <ng-container matColumnDef="accountNumber">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Account No. </th>
        <td mat-cell *matCellDef="let row"> {{row.accountNo}} </td>
      </ng-container>

      <!-- External ID Column -->
      <ng-container matColumnDef="externalId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> External Id </th>
        <td mat-cell *matCellDef="let row">
          <mifosx-external-identifier externalId="{{row.externalId}}"></mifosx-external-identifier>
        </td>
      </ng-container>

      <!-- Status Column -->
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef> Status </th>
        <td mat-cell *matCellDef="let row">
          <div [ngClass]="row.status.code | statusLookup">
            <fa-icon icon="stop"></fa-icon>
            <span class="m-l-10 status">
              {{ row.status.value | translate }}
            </span>
          </div>
        </td>
      </ng-container>

      <!-- Color Column -->
      <ng-container matColumnDef="officeName">
        <th mat-header-cell *matHeaderCellDef> Office Name </th>
        <td mat-cell *matCellDef="let row"> {{row.officeName}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>

      <tr mat-row *matRowDef="let row; columns: displayedColumns;" [routerLink]="[row.id,'general']" class="select-row">
      </tr>
    </table>

    <mat-paginator [pageSize]="pageSize" [pageSizeOptions]="[5, 50, 100]" [length]="totalRows" (page)="pageChanged($event)"
      showFirstLastButtons></mat-paginator>
  </div>

  <div class="alert" [hidden]="!notExistsClientsToFilter">
    <div class="message">
      <i class="fa fa-exclamation-circle alert-check"></i>
      No client was found
    </div>
  </div>
</mat-card>
