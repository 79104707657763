<ng-container>
  <div>
    <form #formRef='ngForm' [formGroup]='messageForm' fxLayout='row' fxLayoutAlign='start center' fxLayoutGap='20px'>
      <mat-form-field fxFlex='calc(90%-20px)'>
        <div>
          <textarea required formControlName='message' matInput placeholder='Write a message here ....'
                    cdkTextareaAutosize
                    cdkAutosizeMinRows='2'></textarea>
          <div fxFlex='4%' fxFlexAlign='end'>
            <button mat-icon-button (click)='uploadFile.click()'>
              <fa-icon icon='paperclip' size='lg'></fa-icon>
            </button>
          </div>
        </div>
      </mat-form-field>

      <input type='file' #uploadFile (change)='onFileChanged($event)' [style.display]="'none'" multiple>

      <button mat-raised-button fxFlex color='primary' [disabled]='!messageForm.valid' (click)='sendMessage()'>
        <fa-icon icon='paper-plane'></fa-icon>
        Send
      </button>
    </form>
  </div>
  <div class='flex items-center gap-2 flex-row flex-wrap mb-8'>
    <div *ngFor='let selected of fileNameList;let index = index'
         class='flex flex-row justify-between items-center gap-2 pl-[0.6rem] pr-0 py-0 rounded-[5px] grey-background'>
      <fa-icon icon='file'></fa-icon>
      <span>{{selected}}</span>
      <button mat-icon-button (click)='removeSelectedFile(index)'>
        <fa-icon icon='times'></fa-icon>
      </button>
    </div>
  </div>

  <div>
    <div class='divide-y'>
      <div *ngFor='let entityMessage of messagesList; let i=index;' class='flex items-center gap-6 flex-row pt-5 pb-2'>
        <div class='self-start pt-1.5'>
          <fa-icon icon='comment-dots' matListIcon></fa-icon>
        </div>

        <div>
          <h3 matLine>{{ entityMessage.message }}</h3>
          <p matLine class='text-gray-400'>
            <span>Sent by: {{entityMessage.sender}}</span><br />
            <span>Date: {{entityMessage.createdDate  | dateFormat}}</span>
          </p>

          <div matLine>
            <div class='flex items-center gap-2 flex-row flex-wrap' *ngIf='entityMessage.documentIdList?.length>0'>
              <div *ngFor='let document of entityMessage.documentIdList;let index = index'
                   class='flex flex-row justify-between items-center gap-2 p-2 rounded-[5px] grey-background cursor-pointer'
                   (click)='onDownload(document)'>
                <fa-icon icon='file'></fa-icon>
                <span>{{entityMessage.documentNameList[index]}}</span>
              </div>
            </div>
          </div>
        </div>

        <div class='flex ms-auto'>
          <button mat-button color='primary' (click)='editMessage(entityMessage.id, entityMessage.message, i)'>
            <fa-icon icon='edit'></fa-icon>
          </button>
          <button mat-button color='warn' (click)='deleteMessage(entityMessage.id, i)'>
            <fa-icon icon='trash'></fa-icon>
          </button>
        </div>
      </div>
    </div>
    <mat-paginator *ngIf='messagesList?.length>0 && !searchResultFlag'
                   (page)='onPageChange($event)'
                   [length]='totalElements'
                   [pageSize]='itemsPerPage'
                   [pageSizeOptions]='[5, 10, 25, 100]'>
    </mat-paginator>
  </div>
</ng-container>
