<div class='flex items-center flex-row justify-between flex-nowrap'>
  <h2>Messages</h2>
  <div>
    <form [formGroup]='searchForm'>
      <mat-form-field>
        <mat-icon matPrefix>
          <fa-icon icon='search' size='sm' style='color: gray'></fa-icon>
        </mat-icon>
        <input matInput formControlName='search' (keyup.enter)='onEnter()' placeholder='Search'>
        <button matSuffix mat-icon-button aria-label="Clear" type='button' [ngStyle]="{'visibility': (!!searchForm.value.search) ? 'visible' : 'hidden'}"  (click)="searchForm.reset()">
          <fa-icon icon='times'></fa-icon>
        </button>
      </mat-form-field>
    </form>
  </div>
</div>
