import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getNotificationRoute'
})
export class GetNotificationRoutePipe implements PipeTransform {

  private routeLoanMap: { [key: string]: string } = {
    'created': 'repayment-schedule',
    'contractSigned': 'loan-contracts'
  };

  private routeChatMap: { [key: string]: string } = {
    'created': 'messages'
  };

  transform(value: string | number, status: string): string {
    const keyStr = value.toString();
    if (keyStr === 'loan') {
      return this.routeLoanMap.hasOwnProperty(status) ? this.routeLoanMap[status] : 'general';
    } else if (keyStr === 'chat') {
      return this.routeChatMap.hasOwnProperty(status) ? this.routeChatMap[status] : 'general';
    } else {
      return 'general';
    }
  }

}
