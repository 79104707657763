<form [formGroup]="smsCampaignDetailsForm">

  <div fxLayout="row wrap" fxLayoutGap="2%" fxLayout.lt-md="column" fxLayoutAlign.gt-sm="start center">

    <mat-form-field fxFlex="48%">
      <mat-label>Campaign Name</mat-label>
      <input matInput formControlName="campaignName" required>
      <mat-error>
        Campaign Name is <strong>required</strong>
      </mat-error>
    </mat-form-field>

    <mat-form-field fxFlex="48%"
      *ngIf="!smsCampaignDetailsForm.controls.isNotification.value">
      <mat-label>SMS Provider</mat-label>
      <mat-select formControlName="providerId">
        <mat-option *ngFor="let provider of smsProviders" [value]="provider.id">
          {{ provider.value }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field fxFlex="48%">
      <mat-label>Trigger Type</mat-label>
      <mat-select formControlName="triggerType" required>
        <mat-option *ngFor="let triggerType of triggerTypes" [value]="triggerType.id">
          {{ triggerType.value }}
        </mat-option>
      </mat-select>
      <mat-error>
        Trigger Type is <strong>required</strong>
      </mat-error>
    </mat-form-field>

    <mat-checkbox labelPosition="before" formControlName="isNotification" fxFlex="48%">
      is Notification?
    </mat-checkbox>

    <mat-form-field fxFlex="48%"
      *ngIf="smsCampaignDetailsForm.contains('recurrenceStartDate')" (click)="recurrenceStartDatePicker.open()">
      <mat-label>Schedule Date</mat-label>
      <input matInput [min]="minDate" [max]="maxDate" [matDatepicker]="recurrenceStartDatePicker"
        formControlName="recurrenceStartDate" required>
      <mat-datepicker-toggle matSuffix [for]="recurrenceStartDatePicker"></mat-datepicker-toggle>
      <mat-datepicker #recurrenceStartDatePicker></mat-datepicker>
      <mat-error>
        Schedule Date is <strong>required</strong>
      </mat-error>
    </mat-form-field>

    <mat-form-field fxFlex="48%"
      *ngIf="smsCampaignDetailsForm.contains('frequency')">
      <mat-label>Repeats</mat-label>
      <mat-select formControlName="frequency" required>
        <mat-option [value]="1">Daily</mat-option>
        <mat-option [value]="2">Weekly</mat-option>
        <mat-option [value]="3">Monthly</mat-option>
        <mat-option [value]="4">Yearly</mat-option>
      </mat-select>
      <mat-error>
        Repetition Frequency is <strong>required</strong>
      </mat-error>
    </mat-form-field>

    <mat-form-field fxFlex="48%"
      *ngIf="smsCampaignDetailsForm.contains('interval')">
      <mat-label>Repetition Interval</mat-label>
      <mat-select formControlName="interval" required>
        <mat-option *ngFor="let interval of repetitionIntervals" [value]="interval">
          {{ interval }}
        </mat-option>
      </mat-select>
      <mat-error>
        Repetition Interval is <strong>required</strong>
      </mat-error>
    </mat-form-field>

    <mat-form-field fxFlex="48%"
      *ngIf="smsCampaignDetailsForm.contains('repeatsOnDay')">
      <mat-label>Repeats on Day</mat-label>
      <mat-select formControlName="repeatsOnDay" required>
        <mat-option value="1">Monday</mat-option>
        <mat-option value="2">Tuesday</mat-option>
        <mat-option value="3">Wednesday</mat-option>
        <mat-option value="4">Thursday</mat-option>
        <mat-option value="5">Friday</mat-option>
        <mat-option value="6">Saturday</mat-option>
        <mat-option value="7">Sunday</mat-option>
      </mat-select>
      <mat-error>
        At least <strong>one</strong> day must be selected
      </mat-error>
    </mat-form-field>

    <mat-form-field fxFlex="48%">
      <mat-label>Business Rule</mat-label>
      <mat-select formControlName="runReportId" required>
        <mat-option *ngFor="let rule of businessRules" [value]="rule.reportId">
          {{ rule.reportName }}
        </mat-option>
      </mat-select>
      <mat-error>
        Business Rule is <strong>required</strong>
      </mat-error>
    </mat-form-field>

  </div>

  <div fxLayout="row" class="margin-t" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="2%"
    *ngIf="!smsCampaignDetailsForm.controls.runReportId.value">
    <button mat-raised-button matStepperPrevious disabled>
      <fa-icon icon="arrow-left" class="m-r-10"></fa-icon>
      Previous
    </button>
    <button mat-raised-button matStepperNext>
      Next
      <fa-icon icon="arrow-right" class="m-l-10"></fa-icon>
    </button>
  </div>

  <mifosx-business-rule-parameters
    *ngIf="smsCampaignDetailsForm.controls.runReportId.value"
    [paramData]="paramData"
    (templateParameters)="passParameters($event)"
  >
  </mifosx-business-rule-parameters>

</form>
