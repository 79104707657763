import { Component, Inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import html2canvas from 'html2canvas';
import JsPDF from 'jspdf';

@Component({
  selector: 'mifosx-pdf-view',
  templateUrl: './pdf-view.component.html',
})
export class PdfViewComponent {
  constructor(
    public dialogRef: MatDialogRef<PdfViewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  onNoClick = () => {
    this.dialogRef.close();
  }

  downloadPdf = () => {
      const element = document.getElementById('pdfView'); // Replace with your element ID
    html2canvas(element, {
        scale: 2
      }).then(canvas => {
        const imgData = canvas.toDataURL('image/png');
        const ratio = canvas.height / canvas.width;
        const pdf = new JsPDF('l');
        const width = pdf.internal.pageSize.width;
        const height = width * ratio;
        // @ts-ignore
        pdf.addImage(imgData, 'JPEG', width * .025, 20, width * .95, height * .95);
        pdf.save(`${this.data.title}` + '.pdf');
      });
  }
}
