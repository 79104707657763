<div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column" class="container m-b-20">
  <button mat-raised-button color="primary" [routerLink]="['edit']" *mifosxHasPermission="'UPDATE_CHARGE'">
    <fa-icon icon="edit" class="m-r-10"></fa-icon>
    Edit
  </button>
  <button mat-raised-button color="warn" (click)="deleteCharge()" *mifosxHasPermission="'DELETE_CHARGE'">
    <fa-icon icon="trash" class="m-r-10"></fa-icon>
    Delete
  </button>
</div>

<div class="container">

  <mat-card>

    <mat-card-content>

      <div fxLayout="row wrap" class="content">

        <div fxFlex="50%" class="mat-body-strong">
          Charge Name
        </div>

        <div fxFlex="50%">
          {{ chargeData.name }}
        </div>

        <div fxFlex="50%" class="mat-body-strong">
          Charge Type
        </div>

        <div fxFlex="50%">
          {{ chargeData.penalty === true ? 'Penalty' : 'Charge' }}
        </div>

        <div fxFlex="50%" class="mat-body-strong">
          Currency
        </div>

        <div fxFlex="50%">
          {{ chargeData.currency.name }}
        </div>

        <div fxFlex="50%" class="mat-body-strong">
          Amount
        </div>

        <div fxFlex="50%">
          {{ chargeData.amount }}
        </div>

        <div fxFlex="50%" class="mat-body-strong">
          Charge Time Type
        </div>

        <div fxFlex="50%">
          {{ chargeData.chargeTimeType.value }}
        </div>

        <div fxFlex="50%" class="mat-body-strong">
          Charge Applies to
        </div>

        <div fxFlex="50%">
          {{ chargeData.chargeAppliesTo.value }}
        </div>

        <div fxFlex="50%" class="mat-body-strong">
          Charge Calculation Type
        </div>

        <div fxFlex="50%">
          {{ chargeData.chargeCalculationType.value }}
        </div>

        <div fxFlex="50%" class="mat-body-strong">
          Charge Payment Mode
        </div>

        <div fxFlex="50%">
          {{ chargeData.chargePaymentMode.value }}
        </div>

        <div fxFlex="50%" class="mat-body-strong">
          Active
        </div>

        <div fxFlex="50%">
          {{ chargeData.active === true ? 'Yes' : 'No' }}
        </div>

        <div fxFlex="50%" class="mat-body-strong" *ngIf="chargeData.chargeTimeType.id == 9 && chargeData.feeFrequency">
          Fee Frequency
        </div>

        <div fxFlex="50%" *ngIf="chargeData.chargeTimeType.id == 9 && chargeData.feeFrequency && chargeData.feeFrequency">
          {{ chargeData.feeFrequency.value }}
        </div>

        <div fxFlex="50%" class="mat-body-strong" *ngIf="chargeData.chargeTimeType.id == 9 && chargeData.feeInterval">
          Frequency Interval
        </div>

        <div fxFlex="50%" *ngIf="chargeData.chargeTimeType.id == 9 && chargeData.feeInterval">
          {{ chargeData.feeInterval }}
        </div>

        <div fxFlex="50%" class="mat-body-strong" *ngIf="chargeData.incomeOrLiabilityAccount">
          Income from Charges
        </div>

        <div fxFlex="50%" *ngIf="chargeData.incomeOrLiabilityAccount">
          {{ chargeData.incomeOrLiabilityAccount.name + ' (' + chargeData.incomeOrLiabilityAccount.glCode + ')' }}
        </div>

        <div fxFlex="50%" class="mat-body-strong" *ngIf="chargeData.taxGroup">
          Tax Group
        </div>

        <div fxFlex="50%" *ngIf="chargeData.taxGroup">
          {{ chargeData.taxGroup.name }}
        </div>

      </div>

      <div fxLayout="row" fxLayoutAlign="center" fxLayoutGap="2%" fxLayout.lt-md="column">
        <button type="button" mat-raised-button color="primary" [routerLink]="['../']">{{ 'Back' | translate }}</button>
      </div>
    </mat-card-content>

  </mat-card>

</div>
