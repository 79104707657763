<!-- The viewport is divided into two parts having max-width 70% and 30% -->
<div class="row">
  <div class="container">
<!--    <mifosx-server-selector class="p-l-10 p-t-10" *ngIf="environment.allowServerSwitch"></mifosx-server-selector>-->
    <mifosx-language-selector class="p-r-10 p-t-10 flex justify-end"></mifosx-language-selector>
    <!-- Tenant Identifier Name -->
<!--    <div fxLayout="row" fxLayoutAlign="center center" fxFlex="1 0 auto">-->
<!--      <mifosx-tenant-selector></mifosx-tenant-selector>-->
<!--    </div>-->
  </div>
</div>
<div class="row">
  <div class="container">
    <div class="max-w-xs block m-auto">
      <a href="/login"><img src="assets/images/harmoto.jpeg" alt="{{ 'APP_NAME' | translate }} Logo" class="img-container"></a>
    </div>
    <hr/>
    <div class="max-w-xs block m-auto mt-8">
      <mifosx-login-form *ngIf="!resetPassword && !twoFactorAuthenticationRequired"></mifosx-login-form>
    </div>
  </div>
</div>

<!-- Contact Information/Resources Menus -->
<mat-menu #resourcesMenu="matMenu">
  <a href="https://mifosforge.jira.com/wiki/spaces/docs/pages/52035622/User+Manual" target="_blank" mat-menu-item>{{ 'labels.links.User Manual' | translate }}</a>
  <a href="https://cwiki.apache.org/confluence/display/FINERACT/Apache+Fineract+1.0+Functional+Specifications" target="_blank" mat-menu-item>{{ 'labels.links.Functional Specifications' | translate }}</a>
  <a href="https://cwiki.apache.org/confluence/display/FINERACT/Contributor%27s+Zone" target="_blank" mat-menu-item>{{ 'labels.links.Developer Zone' | translate }}</a>
</mat-menu>

<mat-menu #communityMenu="matMenu">
  <a href="https://groups.google.com/forum/#!forum/mifosusers" target="_blank" mat-menu-item>{{ 'labels.links.User Group' | translate }}</a>
  <a href="https://groups.google.com/forum/#!forum/mifosdeveloper" target="_blank" mat-menu-item>{{ 'labels.links.Developer Group' | translate }}</a>
  <a href="http://mifos.org/resources/community/communications/#mifos-irc" target="_blank" mat-menu-item>{{ 'labels.links.IRC' | translate }}</a>
</mat-menu>

<mat-menu #contributeMenu="matMenu">
  <a href="https://mifosforge.jira.com/wiki/spaces/MDZ/pages/92012624/Key+Design+Principles" target="_blank" mat-menu-item>{{ 'labels.links.Key Design Principles' | translate }}</a>
  <a href="https://sourceforge.net/projects/mifos/" target="_blank" mat-menu-item>{{ 'labels.links.Working with Code' | translate }}</a>
  <a href="http://mifos.org/donate/" target="_blank" mat-menu-item>{{ 'labels.links.Donate' | translate }}</a>
</mat-menu>
