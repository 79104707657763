<div class="container">

  <mat-card>

    <div fxLayout="row" fxLayout.lt-md="column">

      <div fxFlex="50%">

        <mat-nav-list>

        <div #loanProducts>
          <mat-list-item [routerLink]="['loan-products']" *mifosxHasPermission="'READ_LOANPRODUCT'">
            <mat-icon matListIcon>
              <fa-icon icon="briefcase" size="sm"></fa-icon>
            </mat-icon>
            <h4 matLine>Loan Products</h4>
            <p matLine>Add new loan product or modify or inactivate loan product</p>
          </mat-list-item>
        </div>

        <div #savingsProducts fxHide>
          <mat-list-item [routerLink]="['saving-products']" *mifosxHasPermission="'READ_SAVINGSPRODUCT'">
            <mat-icon matListIcon>
              <fa-icon icon="briefcase" size="sm"></fa-icon>
            </mat-icon>
            <h4 matLine>Savings Products</h4>
            <p matLine>Add new savings product or modify or inactivate savings product</p>
          </mat-list-item>
        </div>

        <div #shareProducts fxHide>
          <mat-list-item [routerLink]="['share-products']" *mifosxHasPermission="'READ_SHAREPRODUCT'">
            <mat-icon matListIcon>
              <fa-icon icon="briefcase" size="sm"></fa-icon>
            </mat-icon>
            <h4 matLine>Share Products</h4>
            <p matLine>Add new share product or modify or inactivate share product</p>
          </mat-list-item>
        </div>

        <div #charges>
          <mat-list-item [routerLink]="['charges']" *mifosxHasPermission="'READ_CHARGE'">
            <mat-icon matListIcon>
              <fa-icon icon="money-bill-alt" size="sm"></fa-icon>
            </mat-icon>
            <h4 matLine>Charges</h4>
            <p matLine>Define charges/penalties for loan products, savings and deposit products</p>
          </mat-list-item>
        </div>

          <mat-list-item [routerLink]="['collaterals']">
            <mat-icon matListIcon>
              <fa-icon icon="money-bill-alt" size="sm"></fa-icon>
            </mat-icon>
            <h4 matLine>Collateral Management</h4>
            <p matLine>Define collaterals for Collateral Management</p>
          </mat-list-item>

          <mat-list-item [routerLink]="['delinquency-bucket-configurations']" *mifosxHasPermission="'READ_DELINQUENCY_BUCKET'">
            <mat-icon matListIcon>
              <fa-icon icon="briefcase" size="sm"></fa-icon>
            </mat-icon>
            <h4 matLine>Delinquency Buckets</h4>
            <p matLine>Define delinquency day ranges and bucket set for loan products</p>
          </mat-list-item>

        </mat-nav-list>

      </div>

      <div fxFlex="50%">

        <mat-nav-list>

          <mat-list-item [routerLink]="['products-mix']" *mifosxHasPermission="'READ_PRODUCTMIX'" fxHide>
            <mat-icon matListIcon>
              <fa-icon icon="random" size="sm"></fa-icon>
            </mat-icon>
            <h4 matLine>Products Mix</h4>
            <p matLine>Defines rules for taking multiple rules</p>
          </mat-list-item>

        <div #fixedDepositProducts>
          <mat-list-item [routerLink]="['fixed-deposit-products']" *mifosxHasPermission="'READ_FIXEDDEPOSITPRODUCT'">
            <mat-icon matListIcon>
              <fa-icon icon="briefcase" size="sm"></fa-icon>
            </mat-icon>
            <h4 matLine>Fixed Deposit Products</h4>
            <p matLine>Add, modify or inactivate a Fixed deposit product</p>
          </mat-list-item>
        </div>

        <div #recurringDepositProducts>
          <mat-list-item [routerLink]="['recurring-deposit-products']" *mifosxHasPermission="'READ_RECURRINGDEPOSITPRODUCT'">
            <mat-icon matListIcon>
              <fa-icon icon="briefcase" size="sm"></fa-icon>
            </mat-icon>
            <h4 matLine>Recurring Deposit Products</h4>
            <p matLine>Add, modify or inactivate a Recurring Deposit product</p>
          </mat-list-item>
        </div>

          <mat-list-item [routerLink]="['tax-configurations']" *mifosxHasPermission="'READ_TAXGROUP'">
            <mat-icon matListIcon>
              <fa-icon icon="cogs" size="sm"></fa-icon>
            </mat-icon>
            <h4 matLine>Manage Tax Configurations</h4>
            <p matLine>Define Tax components and Tax groups</p>
          </mat-list-item>

          <mat-list-item [routerLink]="['floating-rates']" *mifosxHasPermission="'READ_FLOATINGRATE'">
            <mat-icon matListIcon>
              <fa-icon icon="money-bill-alt" size="sm"></fa-icon>
            </mat-icon>
            <h4 matLine>Floating Rates</h4>
            <p matLine>Define floating rates for loan products</p>
          </mat-list-item>

        </mat-nav-list>

      </div>

    </div>

  </mat-card>

</div>

<ng-template #templateCharges let-popover="popover">
  <h2>Charges</h2>
  <p class="mw400">Mifos supports two types of charges—fees and penalties.Fees are charged for services—for example, membership fees, loan disbursement fees, withdrawal fees. Penalties are charged to discourage clients from deviating from the terms of a product</p>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn" (click)="popover.close();configurationWizardService.closeConfigWizard()">Close</button>
    <button mat-raised-button color="primary" (click)="popover.close();previousStepCharges()">Back</button>
    <button mat-raised-button color="primary" (click)="popover.close();nextStepCharges()">Next</button>
  </div>
</ng-template>

<ng-template #templateLoanProducts let-popover="popover">
  <h2>Loan Products</h2>
  <p class="mw400">Loan products define the rules, default settings, and constraints for a financial institution's lending offerings. A loan product provides a template for multiple loan accounts for the financial institution's clients.</p>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn" (click)="popover.close();configurationWizardService.closeConfigWizard()">Close</button>
    <button mat-raised-button color="primary" (click)="popover.close();previousStepLoanProducts()">Back</button>
    <button mat-raised-button color="primary" (click)="popover.close();nextStepLoanProducts()">Next</button>
  </div>
</ng-template>

<ng-template #templateSavingsProducts let-popover="popover">
  <h2>Savings Products</h2>
  <p class="mw400">Savings products define the rules, default settings, and constraints for a financial institution's current/checking and passbook savings accounts. A saving product provides a template for multiple accounts that are or will be held by the financial institution's clients, groups and centers.</p>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn" (click)="popover.close();configurationWizardService.closeConfigWizard()">Close</button>
    <button mat-raised-button color="primary" (click)="popover.close();previousStepSavingsProducts()">Back</button>
    <button mat-raised-button color="primary" (click)="popover.close();nextStepSavingsProducts()">Next</button>
  </div>
</ng-template>

<ng-template #templateShareProducts let-popover="popover">
  <h2>Share Products</h2>
  <p class="mw400">Share products define the rules, default settings, and constraints for a financial institution's Shares and dividends. A share product provides a template for multiple accounts that are or will be held by the financial institution's clients.</p>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn" (click)="popover.close();configurationWizardService.closeConfigWizard()">Close</button>
    <button mat-raised-button color="primary" (click)="popover.close();previousStepShareProducts()">Back</button>
    <button mat-raised-button color="primary" (click)="popover.close();nextStepShareProducts()">Next</button>
  </div>
</ng-template>

<ng-template #templateFixedDepositProducts let-popover="popover">
  <h2>Fixed Deposit Products</h2>
  <p class="mw400">Fixed Deposit Products defines the rules, default settings, and constraints for a financial institution's <br> fixed deposit offerings (also referred to as term deposits). A fixed deposit product provides a template for multiple fixed deposit accounts for the financial institution's clients.</p>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn" (click)="popover.close();configurationWizardService.closeConfigWizard()">Close</button>
    <button mat-raised-button color="primary" (click)="popover.close();previousStepFixedDepositProducts()">Back</button>
    <button mat-raised-button color="primary" (click)="popover.close();nextStepFixedDepositProducts()">Next</button>
  </div>
</ng-template>

<ng-template #templateRecurringDepositProducts let-popover="popover">
  <h2>Recurring Deposit Products</h2>
  <p class="mw400">Recurring Deposit products define the rules, default settings, and constraints for a financial institution's recurring deposit offerings (a type of term deposit with recurring deposits). A recurring deposit product provides a template for multiple recurring deposit accounts for the financial institution's clients.</p>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn" (click)="popover.close();configurationWizardService.closeConfigWizard()">Close</button>
    <button mat-raised-button color="primary" (click)="popover.close();previousStepRecurringDepositProducts()">Back</button>
    <button mat-raised-button color="primary" (click)="popover.close();nextStepRecurringDepositProducts()">Next</button>
  </div>
</ng-template>
