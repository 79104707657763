<div class="container space">

  <mat-card>

    <form [formGroup]="configurationForm" (ngSubmit)="submit()">

      <mat-card-content>

        <div fxLayout="column">

          <mat-form-field>
            <mat-label>Configuration Name</mat-label>
            <input matInput required formControlName="name">
          </mat-form-field>

          <mat-form-field>
            <mat-label>Description</mat-label>
            <textarea matInput formControlName="description" cdkTextareaAutosize cdkAutosizeMinRows="2"></textarea>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Number Value</mat-label>
            <input matInput type="number" formControlName="value">
          </mat-form-field>

          <mat-form-field>
            <mat-label>String Value</mat-label>
            <input matInput formControlName="stringValue">
          </mat-form-field>

          <mat-form-field fxFlex="48%" (click)="configurationDatePicker.open()">
            <mat-label>Date Value</mat-label>
            <input matInput [min]="minDate" [max]="maxDate" [matDatepicker]="configurationDatePicker" formControlName="dateValue">
            <mat-datepicker-toggle matSuffix [for]="configurationDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #configurationDatePicker></mat-datepicker>
          </mat-form-field>

        </div>

      </mat-card-content>

      <mat-card-actions fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="5px">
        <button type="button" mat-raised-button [routerLink]="['../..']"> Cancel </button>
        <button mat-raised-button color="primary" [disabled]="configurationForm.pristine || !configurationForm.valid">
          Submit </button>
      </mat-card-actions>

    </form>

  </mat-card>

</div>
