<!-- TODO: Add user specific settings for choice of language and date format. -->

<!-- Global Web App Configuration Settings -->
<div class="container">

  <mat-accordion>

    <mat-expansion-panel>

      <mat-expansion-panel-header>
        <mat-panel-title>
          Main Configuration
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div fxLayout="column">

        <mat-form-field>
          <mat-label>Default Language</mat-label>
          <mat-select [formControl]="language" [compareWith]="compareOptions">
            <mat-option *ngFor="let language of languages" [value]="language">
              {{ language.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Default Date Format</mat-label>
          <mat-select [formControl]="dateFormat">
            <mat-option *ngFor="let dateFormat of dateFormats" [value]="dateFormat">
              {{ dateFormat }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Decimals to Display</mat-label>
          <mat-select [formControl]="decimalsToDisplay">
            <mat-option *ngFor="let decimalOpt of decimals" [value]="decimalOpt">
              {{ decimalOpt }}
            </mat-option>
          </mat-select>
        </mat-form-field>

      </div>

    </mat-expansion-panel>

    <mat-expansion-panel>

      <mat-expansion-panel-header>
        <mat-panel-title>
          Images
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div fxLayout="column">

        <span class="header">Favicon</span>
        <mifosx-file-upload></mifosx-file-upload>

        <span class="header">Cover Image</span>
        <mifosx-file-upload></mifosx-file-upload>

        <span class="header">Logo</span>
        <mifosx-file-upload></mifosx-file-upload>

        <span class="header">Logo with Organization Name</span>
        <mifosx-file-upload></mifosx-file-upload>

      </div>

    </mat-expansion-panel>

    <mat-expansion-panel>

      <mat-expansion-panel-header>
        <mat-panel-title>
          Theme and Font
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div fxLayout="column">

        <span class="header">Theme</span>
<!--        <mifosx-theme-picker></mifosx-theme-picker>-->

        <mat-form-field>
          <mat-label>Default Font</mat-label>
          <mat-select>
            <mat-option *ngFor="let font of fonts" [value]="font">
              {{ font }}
            </mat-option>
          </mat-select>
        </mat-form-field>

      </div>

    </mat-expansion-panel>

    <mat-expansion-panel>

      <mat-expansion-panel-header>
        <mat-panel-title>
          Contact Information
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div fxLayout="column">

        <mat-form-field>
          <mat-label>Website</mat-label>
          <input type="url" matInput>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Email</mat-label>
          <input type="email" matInput>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Contact No</mat-label>
          <input type="tel" matInput>
        </mat-form-field>

      </div>

    </mat-expansion-panel>

  </mat-accordion>

</div>
