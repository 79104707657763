<button
  mat-icon-button class='ml-1 custom-badge' matTooltip='Notifications'
  [matMenuTriggerFor]='notificationsMenu' (menuClosed)='menuClosed()'
  [matBadge]='displayCount' [matBadgeHidden]='!unreadNotifications.length' matBadgeColor='warn' matBadgeSize='medium'
>
  <fa-icon icon='bell' size='lg'></fa-icon>
</button>

<mat-menu class='notifications-menu' #notificationsMenu='matMenu' [overlapTrigger]='false'>

  <ng-container *ngFor='let notification of unreadNotifications'>
    <mifosx-notification-item
      [content]='notification.content.toString()'
      [createdAt]='notification.createdAt'
      [objectId]='notification.objectId'
      [objectType]='notification.objectType'
      [status]='notification.action'
      [icon]='"university"'
      [routerLink]="notification.clientId ?
      ['/clients/'+ notification?.clientId + '/' + routeMap[notification.objectType], notification.objectId, notification.objectType | getNotificationRoute : notification.action]:
      [routeMap[notification.objectType], notification.objectId, notification.objectType | getNotificationRoute : notification.action]"
      [isRead]='false'
    >
    </mifosx-notification-item>
    <mat-divider></mat-divider>
  </ng-container>

  <ng-container *ngFor='let notification of displayedReadNotifications'>
    <mifosx-notification-item
      [content]='notification.content.toString()'
      [createdAt]='notification.createdAt'
      [objectId]='notification.objectId'
      [objectType]='notification.objectType'
      [status]='notification.action'
      [icon]='"university"'
      [routerLink]="notification.clientId ?
      ['/clients/'+ notification?.clientId + '/' + routeMap[notification.objectType], notification.objectId, notification.objectType | getNotificationRoute : notification.action]:
      [routeMap[notification.objectType], notification.objectId, notification.objectType | getNotificationRoute : notification.action]"
      [isRead]='true'
    >
    </mifosx-notification-item>
    <mat-divider></mat-divider>
  </ng-container>
</mat-menu>
