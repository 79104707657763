export enum NotificationStatus {
  'CREATED' = 'created',
  'APPROVED' = 'approved',
  'REPAYMENT_MADE' = 'repaymentMade',
  'LOAN_RESCHEDULED' = 'loanRescheduled',
  'CLOSED' = 'closed',
  'DISBURSED' = 'disbursed',
  'CONTRACT_SIGNED' = 'contractSigned',
  'CONTRACT_SIGNING_REQUIRED' = 'contractSigningRequired',
  'PAYMENT_DUE' = 'paymentDue',
}

export enum NotificationType {
  'LOAN' = 'loan',
  'LOAN_PRODUCT' = 'loanProduct',
  'CLIENT' = 'client',
  'CHAT' = 'chat'
}
