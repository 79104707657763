<form [formGroup]="recurringDepositProductDetailsForm">

  <div fxLayout="row wrap" fxLayoutGap="2%" fxLayout.lt-md="column">

    <mat-form-field fxFlex="48%">
      <mat-label>Product Name</mat-label>
      <input matInput formControlName="name" required>
      <mat-error *ngIf="recurringDepositProductDetailsForm.controls.name.hasError('required')">
        Product Name is <strong>required</strong>
      </mat-error>
    </mat-form-field>

    <mat-form-field fxFlex="48%">
      <mat-label>Short Name</mat-label>
      <input matInput formControlName="shortName" maxlength="4" required>
      <mat-error *ngIf="recurringDepositProductDetailsForm.controls.shortName.hasError('required')">
        Short Name is <strong>required</strong>
      </mat-error>
    </mat-form-field>

    <mat-form-field fxFlex="98%">
      <mat-label>Description</mat-label>
      <textarea matInput formControlName="description" cdkTextareaAutosize cdkAutosizeMinRows="2"></textarea>
      <mat-error *ngIf="recurringDepositProductDetailsForm.controls.description.hasError('required')">
        Description is <strong>required</strong>
      </mat-error>
    </mat-form-field>

  </div>

  <div fxLayout="row" class="margin-t" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="2%">
    <button mat-raised-button matStepperPrevious disabled>
      <fa-icon icon="arrow-left" class="m-r-10"></fa-icon>
      Previous
    </button>
    <button mat-raised-button matStepperNext>
      Next
      <fa-icon icon="arrow-right" class="m-l-10"></fa-icon>
    </button>
  </div>

</form>
