<form [formGroup]="savingProductCurrencyForm">

  <div fxLayout="row wrap" fxLayoutGap="2%" fxLayout.lt-md="column">

    <mat-form-field fxFlex="48%">
      <mat-label>Currency</mat-label>
      <mat-select formControlName="currencyCode" matTooltip="The currency to be used" required>
        <mat-option *ngFor="let currency of currencyData" [value]="currency.code">
          {{ currency.name }}
        </mat-option>
      </mat-select>
      <mat-error>
        Currency is <strong>required</strong>
      </mat-error>
    </mat-form-field>

    <mat-form-field fxFlex="48%">
      <mat-label>Decimal Places</mat-label>
      <input type="number" matInput matTooltip="The number of decimal places to be used to track and report on saving accounts" formControlName="digitsAfterDecimal" required>
      <mat-error>
        Decimal Places is <strong>required</strong>
      </mat-error>
    </mat-form-field>

    <mat-form-field fxFlex="48%">
      <mat-label>Currency in multiples of</mat-label>
      <input type="number" matInput matTooltip="The amount to be rounded off (example: 100 rounds off to 200, 300, 400, etc)" formControlName="inMultiplesOf" required>
      <mat-error>
        Currency in multiples of is <strong>required</strong>
      </mat-error>
    </mat-form-field>

  </div>

  <div fxLayout="row" class="margin-t" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="2%">
    <button mat-raised-button matStepperPrevious>
      <fa-icon icon="arrow-left" class="m-r-10"></fa-icon>
      Previous
    </button>
    <button mat-raised-button matStepperNext>
      Next
      <fa-icon icon="arrow-right" class="m-l-10"></fa-icon>
    </button>
  </div>

</form>
