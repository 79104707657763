<div mat-menu-item [ngClass]='!isRead ? "unread": ""'>
  <div class='notification-item'>
    <div class='icon-container'>
      <mat-icon>
        <fa-icon icon='{{icon}}' size='lg'></fa-icon>
      </mat-icon>
    </div>
    <div class='content-container'>
      <span class='purple-color'>{{ createdAt | date: "d MMM y, HH:mm" }}<span class='purple-color'
                                                                               *ngIf='objectType!=="chat" && objectType!=="loanProduct" && (objectType!=="loan" && status!=="paymentDue")'>, {{content}}</span></span>
      <div [ngStyle]='{display: "flex", paddingTop: "10px"}'>
        <p><b>
          <span class='purple-color'>{{objectId}} </span> - {{getNotificationText(objectType)}}
          <span *ngIf='objectType!=="chat"'
                [ngStyle]='{color: getStatusColor(status)}'>{{ getStatusText(status) }}</span><br/>
        </b>
        <span *ngIf='status==="paymentDue"'>{{content}}</span>
        </p>
      </div>
    </div>
  </div>
</div>
