<form [formGroup]="loanProductSettingsForm">
  <div class="grid grid-cols-1 md:grid-cols-2 gap-8 py-4 border-b-4">
    <div class="col-span-1 py-4">
      <div class="flex justify-between items-center">
        <mat-form-field fxFlex="60%">
          <mat-label>{{ 'labels.inputs.products.loan.Amortization' | translate }}</mat-label>
          <mat-select formControlName="amortizationType"
                      matTooltip="The Amortization value is input to calculating the repayment amounts for repayment of the loan."
                      required>
            <mat-option *ngFor="let amortizationType of amortizationTypeData" [value]="amortizationType.id">
              {{ 'labels.inputs.products.loan.amortizationType.' + amortizationType.value | translate }}
            </mat-option>
          </mat-select>
          <mat-error>
            Amortization is <strong>required</strong>
          </mat-error>
        </mat-form-field>
        <mat-checkbox labelPosition="before" formControlName="isEqualAmortization" fxFlex="40%" class="text-right">
          {{ 'labels.inputs.products.loan.Is Equal Amortization?' | translate }}
        </mat-checkbox>
      </div>
      <div class="flex">
        <mat-form-field class="w-full">
          <mat-label>Interest calculation period</mat-label>
          <mat-select formControlName="interestCalculationPeriodType" matTooltip="Daily - Will Calculate the interest on DAILY basis ex: Month of February has 28days
      and it will calculate interest for 28days, SAME AS REPAYMENT PERIOD- it calculates for the month,that is, 30days."
                      required>
            <mat-option *ngFor="let interestCalculationPeriodType of interestCalculationPeriodTypeData"
                        [value]="interestCalculationPeriodType.id">
              {{ interestCalculationPeriodType.value }}
            </mat-option>
          </mat-select>
          <mat-error>
            Interest calculation period is <strong>required</strong>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="flex">
        <mat-checkbox *ngIf="loanProductSettingsForm.value.interestCalculationPeriodType === 1"
                      labelPosition="before"
                      matTooltip=" To be used with SAME AS REPAYMENT PERIOD- for  calculating exact interest with partial period  ex: Interest charged from is 5th of April ,
      Principal is 10000 and interest is 1% per month then the interest will be (10000 * 1%)* (25/30) , it calculates for the month first then calculates exact periods between start date and end date(can be a decimal)"
                      formControlName="allowPartialPeriodInterestCalcualtion">
          Calculate interest for exact days in partial period
        </mat-checkbox>
      </div>
    </div>
    <div class="col-span-1 py-4">
      <div class="flex">
        <mat-form-field class="w-full">
          <mat-label>Interest Method</mat-label>
          <mat-select formControlName="interestType"
                      matTooltip="The Interest method value is input to calculating the payments amount for repayment of the loan. "
                      required>
            <mat-option *ngFor="let interestType of interestTypeData" [value]="interestType.id">
              {{ interestType.value }}
            </mat-option>
          </mat-select>
          <mat-error>
            Interest Method is <strong>required</strong>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="flex">
        <mat-form-field class="w-full">
          <mat-label>Repayment strategy</mat-label>
          <mat-select formControlName="transactionProcessingStrategyId"
                      matTooltip="The repayment strategy determines the sequence in which each of the components is paid."
                      required>
            <mat-option *ngFor="let transactionProcessingStrategy of transactionProcessingStrategyData"
                        [value]="transactionProcessingStrategy.id">
              {{ transactionProcessingStrategy.id }}
            </mat-option>
          </mat-select>
          <mat-error>
            Repayment strategy is <strong>required</strong>
          </mat-error>
        </mat-form-field>
      </div>
    </div>
  </div>


  <div fxLayout="row wrap" fxLayoutGap="2%" fxLayout.lt-md="column" fxLayoutAlign.gt-sm="start center">
    <h4 fxFlex="98%" class="mat-h4">
      Moratorium
      <mat-icon matListIcon>
        <fa-icon icon="info" size="sm"
                 matTooltip="The moratorium information will default from the loan product settings, but can be changed for this loan account"></fa-icon>
      </mat-icon>
    </h4>


    <mat-form-field fxFlex="48%">
      <mat-label>Grace on principal payment</mat-label>
      <input type="number" matInput formControlName="graceOnPrincipalPayment">
    </mat-form-field>

    <mat-form-field fxFlex="48%">
      <mat-label>Grace on interest payment</mat-label>
      <input type="number" matInput formControlName="graceOnInterestPayment">
    </mat-form-field>

    <mat-form-field fxFlex="48%" class="hidden">
      <mat-label>Delinquency Bucket</mat-label>
      <mat-select formControlName="delinquencyBucketId">
        <mat-option *ngFor="let delinquencyBucket of delinquencyBucketData" [value]="delinquencyBucket.id">
          {{ delinquencyBucket.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <div class="w-full shadow-black">
      <hr/>
    </div>

    <mat-form-field fxFlex="48%">
      <mat-label>Interest free period</mat-label>
      <input type="number" matInput matTooltip="If the Interest Free Period is '4' and the client's Repayment Frequency is every week,
      then for the first four weeks the client need not to pay interest, he has to pay principle due for that week only."
             formControlName="graceOnInterestCharged">
    </mat-form-field>

    <mat-form-field fxFlex="48%">
      <mat-label>Arrears tolerance</mat-label>
      <input type="number" matInput matTooltip="With 'Arrears tolerance' you can specify a tolerance amount and if the loan is behind (in arrears),
      but within the tolerance, it won't be classified as 'in arrears' and part of the portfolio at risk."
             formControlName="inArrearsTolerance">
    </mat-form-field>

    <mat-form-field fxFlex="48%">
      <mat-label>Days in year</mat-label>
      <mat-select matTooltip="The setting for number of days in year to use to calculate interest"
                  formControlName="daysInYearType" required>
        <mat-option *ngFor="let daysInYearType of daysInYearTypeData" [value]="daysInYearType.id">
          {{ daysInYearType.value }}
        </mat-option>
      </mat-select>
      <mat-error>
        Days in year is <strong>required</strong>
      </mat-error>
    </mat-form-field>

    <mat-form-field fxFlex="48%">
      <mat-label>Days in month</mat-label>
      <mat-select matTooltip="Number of days in month." formControlName="daysInMonthType" required>
        <mat-option *ngFor="let daysInMonthType of daysInMonthTypeData" [value]="daysInMonthType.id">
          {{ daysInMonthType.value }}
        </mat-option>
      </mat-select>
      <mat-error>
        Days in month is <strong>required</strong>
      </mat-error>
    </mat-form-field>

    <mat-checkbox fxFlex="98%" labelPosition="before" formControlName="canDefineInstallmentAmount" class="margin-v">
      Allow fixing of the installment amount
    </mat-checkbox>

    <div class="w-full">
      <hr/>
    </div>

    <h4 fxFlex="48%" class="mat-h4">Number of days a loan may be overdue before moving into arrears
      <mat-icon matListIcon>
        <fa-icon icon="info" size="sm"
                 matTooltip="A loan is in arrears once the number of days entered into this field is exceeded. If this field is blank, the loan will be in arrears the day after a scheduled payment is missed. "></fa-icon>
      </mat-icon>
    </h4>

    <mat-form-field fxFlex="48%">
      <mat-label>On arrears ageing</mat-label>
      <input type="number" matInput formControlName="graceOnArrearsAgeing">
    </mat-form-field>

    <h4 fxFlex="48%" class="mat-h4">Maximum number of days a loan may be overdue before becoming a NPA (non-performing
      asset)
      <mat-icon matListIcon>
        <fa-icon icon="info" size="sm"
                 matTooltip="A loan is a NPA (non performing asset) once the number of days entered into this field is exceeded. If this field is blank, the loan will be an NPA the day after a scheduled payment is missed."></fa-icon>
      </mat-icon>
    </h4>

    <mat-form-field fxFlex="48%">
      <mat-label>Overdue days for NPA</mat-label>
      <input type="number" matInput formControlName="overdueDaysForNPA">
    </mat-form-field>

    <mat-checkbox fxFlex="48%" labelPosition="before" formControlName="accountMovesOutOfNPAOnlyOnArrearsCompletion"
                  class="margin-v">
      Account moves out of NPA only after all arrears have been cleared?
    </mat-checkbox>

    <mat-form-field fxFlex="48%">
      <mat-label>Principal Threshold (%) for Last Instalment</mat-label>
      <input type="number" matInput formControlName="principalThresholdForLastInstallment">
    </mat-form-field>

    <mat-checkbox fxFlex="48%" labelPosition="before"
                  matTooltip="These fields are used to define the minimum, maximum gap that should be present between the installments for the loan product"
                  formControlName="allowVariableInstallments" class="margin-v">
      Are Variable Installments allowed?
    </mat-checkbox>

    <div *ngIf="loanProductSettingsForm.value.allowVariableInstallments" fxFlexFill fxLayout="row wrap" fxLayoutGap="2%"
         fxLayout.lt-md="column">

      <h4 fxFlex="98%" class="mat-h4">Variable Installments</h4>

      <mat-form-field fxFlex="48%">
        <mat-label>Minimum gap between Installments</mat-label>
        <input type="number" matInput formControlName="minimumGap" required>
        <mat-error>
          Minimum gap between Installments is <strong>required</strong>
        </mat-error>
      </mat-form-field>

      <mat-form-field fxFlex="48%">
        <mat-label>Maximum gap between Installments</mat-label>
        <input type="number" matInput formControlName="maximumGap" required>
        <mat-error>
          Maximum gap between Installments is <strong>required</strong>
        </mat-error>
      </mat-form-field>

    </div>

    <mat-checkbox fxFlex="48%" labelPosition="before"
                  matTooltip="If selected, the Loan Product can be used to apply for Topup Loans."
                  formControlName="canUseForTopup" class="margin-v">
      Allowed to be used for providing Topup Loans
    </mat-checkbox>

    <h3 fxFlex="23%" class="mat-h3">Interest Recalculation</h3>

    <mat-checkbox fxFlex="73%" labelPosition="before" formControlName="isInterestRecalculationEnabled" class="margin-b">
      Recalculate Interest
    </mat-checkbox>

    <div *ngIf="loanProductSettingsForm.value.isInterestRecalculationEnabled" fxFlexFill fxLayout="row wrap"
         fxLayoutGap="2%" fxLayout.lt-md="column">

      <mat-form-field fxFlex="48%">
        <mat-label>Pre-closure interest calculation rule</mat-label>
        <mat-select formControlName="preClosureInterestCalculationStrategy" required>
          <mat-option *ngFor="let preClosureInterestCalculationStrategy of preClosureInterestCalculationStrategyData"
                      [value]="preClosureInterestCalculationStrategy.id">
            {{ preClosureInterestCalculationStrategy.value }}
          </mat-option>
        </mat-select>
        <mat-error>
          Pre-closure interest calculation rule is <strong>required</strong>
        </mat-error>
      </mat-form-field>

      <mat-form-field fxFlex="48%">
        <mat-label>Advance payments adjustment type</mat-label>
        <mat-select formControlName="rescheduleStrategyMethod" required>
          <mat-option *ngFor="let rescheduleStrategyType of rescheduleStrategyTypeData"
                      [value]="rescheduleStrategyType.id">
            {{ rescheduleStrategyType.value }}
          </mat-option>
        </mat-select>
        <mat-error>
          Advance payments adjustment type is <strong>required</strong>
        </mat-error>
      </mat-form-field>

      <mat-form-field fxFlex="48%">
        <mat-label>Interest recalculation compounding on</mat-label>
        <mat-select formControlName="interestRecalculationCompoundingMethod" required>
          <mat-option *ngFor="let interestRecalculationCompoundingType of interestRecalculationCompoundingTypeData"
                      [value]="interestRecalculationCompoundingType.id">
            {{ interestRecalculationCompoundingType.value }}
          </mat-option>
        </mat-select>
        <mat-error>
          Interest recalculation compounding on is <strong>required</strong>
        </mat-error>
      </mat-form-field>

      <span fxFlex="48%" fxHide.lt-md></span>

      <div fxFlexFill fxLayout="row wrap" fxLayoutGap="2%" fxLayout.lt-md="column">

        <mat-form-field fxFlex="48%"
                        *ngIf="loanProductSettingsForm.value.interestRecalculationCompoundingMethod !== 0 && loanProductSettingsForm.value.recalculationCompoundingFrequencyType !== 1">
          <mat-label>Frequency Interval for compounding</mat-label>
          <input type="number" matInput formControlName="recalculationCompoundingFrequencyInterval" required>
          <mat-error>
            Frequency Interval for compounding is <strong>required</strong>
          </mat-error>
        </mat-form-field>

        <mat-form-field fxFlex="48%" *ngIf="loanProductSettingsForm.value.recalculationRestFrequencyType !== 1">
          <mat-label>Frequency Interval for recalculation</mat-label>
          <input type="number" matInput formControlName="recalculationRestFrequencyInterval" required>
          <mat-error>
            Frequency Interval for recalculation is <strong>required</strong>
          </mat-error>
        </mat-form-field>

      </div>

      <div *ngIf="loanProductSettingsForm.value.interestRecalculationCompoundingMethod !== 0" fxFlexFill
           fxLayout="row wrap" fxLayoutGap="2%" fxLayout.lt-md="column">

        <mat-form-field fxFlex="31%">
          <mat-label>Frequency for compounding</mat-label>
          <mat-select formControlName="recalculationCompoundingFrequencyType" required>
            <mat-option *ngFor="let interestRecalculationFrequencyType of interestRecalculationFrequencyTypeData"
                        [value]="interestRecalculationFrequencyType.id">
              {{ interestRecalculationFrequencyType.value }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <div *ngIf="loanProductSettingsForm.value.recalculationCompoundingFrequencyType === 4" fxFlex="31%"
             fxLayout="row" fxLayout.lt-md="column">

          <mat-form-field fxFlexFill>
            <mat-select formControlName="recalculationCompoundingFrequencyNthDayType">
              <mat-option *ngFor="let interestRecalculationNthDayType of interestRecalculationNthDayTypeData"
                          [value]="interestRecalculationNthDayType.id">
                {{ interestRecalculationNthDayType.value }}
              </mat-option>
            </mat-select>
          </mat-form-field>

        </div>

        <div *ngIf="(loanProductSettingsForm.value.recalculationCompoundingFrequencyType === 4 && loanProductSettingsForm.value.recalculationCompoundingFrequencyNthDayType !== -2) ||
        loanProductSettingsForm.value.recalculationCompoundingFrequencyType === 3" fxFlex="31%" fxLayout="row"
             fxLayout.lt-md="column">

          <mat-form-field fxFlexFill>
            <mat-select formControlName="recalculationCompoundingFrequencyDayOfWeekType">
              <mat-option *ngFor="let interestRecalculationDayOfWeekType of interestRecalculationDayOfWeekTypeData"
                          [value]="interestRecalculationDayOfWeekType.id">
                {{ interestRecalculationDayOfWeekType.value }}
              </mat-option>
            </mat-select>
          </mat-form-field>

        </div>

        <div
          *ngIf="loanProductSettingsForm.value.recalculationCompoundingFrequencyType === 4 && loanProductSettingsForm.value.recalculationCompoundingFrequencyNthDayType === -2"
          fxFlex="31%" fxLayout="row" fxLayout.lt-md="column">

          <mat-form-field fxFlexFill>
            <mat-select formControlName="recalculationCompoundingFrequencyOnDayType">
              <mat-option *ngFor="let interestRecalculationOnDayType of interestRecalculationOnDayTypeData"
                          [value]="interestRecalculationOnDayType">
                {{ interestRecalculationOnDayType }}
              </mat-option>
            </mat-select>
          </mat-form-field>

        </div>

      </div>

      <mat-form-field fxFlex="31%">
        <mat-label>Frequency for recalculate Outstanding Principal</mat-label>
        <mat-select formControlName="recalculationRestFrequencyType" required>
          <mat-option *ngFor="let interestRecalculationFrequencyType of interestRecalculationFrequencyTypeData"
                      [value]="interestRecalculationFrequencyType.id">
            {{ interestRecalculationFrequencyType.value }}
          </mat-option>
        </mat-select>
        <mat-error>
          Frequency for recalculate Outstanding Principal is <strong>required</strong>
        </mat-error>
      </mat-form-field>

      <div *ngIf="loanProductSettingsForm.value.recalculationRestFrequencyType === 4" fxFlex="31%" fxLayout="row"
           fxLayout.lt-md="column">

        <mat-form-field fxFlexFill>
          <mat-select formControlName="recalculationRestFrequencyNthDayType">
            <mat-option *ngFor="let interestRecalculationNthDayType of interestRecalculationNthDayTypeData"
                        [value]="interestRecalculationNthDayType.id">
              {{ interestRecalculationNthDayType.value }}
            </mat-option>
          </mat-select>
        </mat-form-field>

      </div>

      <div *ngIf="(loanProductSettingsForm.value.recalculationRestFrequencyType === 4 && loanProductSettingsForm.value.recalculationRestFrequencyNthDayType !== -2) ||
      loanProductSettingsForm.value.recalculationRestFrequencyType === 3" fxFlex="31%" fxLayout="row"
           fxLayout.lt-md="column">

        <mat-form-field fxFlexFill>
          <mat-select formControlName="recalculationRestFrequencyDayOfWeekType">
            <mat-option *ngFor="let interestRecalculationDayOfWeekType of interestRecalculationDayOfWeekTypeData"
                        [value]="interestRecalculationDayOfWeekType.id">
              {{ interestRecalculationDayOfWeekType.value }}
            </mat-option>
          </mat-select>
        </mat-form-field>

      </div>

      <div
        *ngIf="loanProductSettingsForm.value.recalculationRestFrequencyType === 4 && loanProductSettingsForm.value.recalculationRestFrequencyNthDayType === -2"
        fxFlex="31%" fxLayout="row" fxLayout.lt-md="column">

        <mat-form-field fxFlexFill>
          <mat-select formControlName="recalculationRestFrequencyOnDayType">
            <mat-option *ngFor="let interestRecalculationOnDayType of interestRecalculationOnDayTypeData"
                        [value]="interestRecalculationOnDayType">
              {{ interestRecalculationOnDayType }}
            </mat-option>
          </mat-select>
        </mat-form-field>

      </div>

      <mat-checkbox fxFlex="98%" labelPosition="before" formControlName="isArrearsBasedOnOriginalSchedule"
                    class="margin-v">
        Is Arrears recognization based on original schedule?
      </mat-checkbox>

    </div>

    <div class="w-full">
      <hr/>
    </div>

    <h3 fxFlex="23%" class="mat-h3">Guarantee Requirements</h3>

    <mat-checkbox fxFlex="73%" labelPosition="before" formControlName="holdGuaranteeFunds" class="margin-b">
      Place Guarantee Funds On-Hold?
    </mat-checkbox>

    <div *ngIf="loanProductSettingsForm.value.holdGuaranteeFunds" fxFlexFill fxLayout="row wrap" fxLayoutGap="2%"
         fxLayout.lt-md="column">

      <mat-form-field fxFlex="31%">
        <mat-label>Mandatory Guarantee(%)</mat-label>
        <input type="number" matInput formControlName="mandatoryGuarantee" required>
        <mat-error>
          Mandatory Guarantee is <strong>required</strong>
        </mat-error>
      </mat-form-field>

      <mat-form-field fxFlex="31%">
        <mat-label>Minimum Guarantee from Own Funds(%)</mat-label>
        <input type="number" matInput formControlName="minimumGuaranteeFromOwnFunds">
      </mat-form-field>

      <mat-form-field fxFlex="31%">
        <mat-label>Minimum Guarantee from Guarantor Funds(%)</mat-label>
        <input type="number" matInput formControlName="minimumGuaranteeFromGuarantor">
      </mat-form-field>

    </div>

    <div class="w-full">
      <hr/>
    </div>

    <h3 fxFlex="23%" class="mat-h3">Loan Tranche Details</h3>

    <mat-checkbox fxFlex="73%" labelPosition="before" formControlName="multiDisburseLoan" matTooltip="Leave this checkbox unchecked if the loan is a single disburse loan. Check this
    checkbox if the loan is a multi disburse loan. See additional fields for additional information required for this type of loan."
                  class="margin-b">
      Enable Multiple Disbursals
    </mat-checkbox>

    <div *ngIf="loanProductSettingsForm.value.multiDisburseLoan" fxFlexFill fxLayout="row wrap" fxLayoutGap="2%"
         fxLayout.lt-md="column">
      <mat-form-field fxFlex="48%">
        <mat-label>Maximum Tranche count</mat-label>
        <input type="number" matInput matTooltip="The maximum number of disbursements allowed for a loan account."
               formControlName="maxTrancheCount" required>
        <mat-error>
          Maximum Tranche count is <strong>required</strong>
        </mat-error>
      </mat-form-field>

      <mat-form-field fxFlex="48%">
        <mat-label>Maximum allowed outstanding balance</mat-label>
        <input type="number" matInput
               matTooltip="The maximum outstanding loan account balance allowed at a point in time."
               formControlName="outstandingLoanBalance">
      </mat-form-field>

      <mat-checkbox fxFlex="48%" labelPosition="before" formControlName="disallowExpectedDisbursements"
                    class="margin-v">
        Disallow Expected Disbursements
      </mat-checkbox>
    </div>

    <div class="w-full">
      <hr/>
    </div>

    <h3 class="text-xl font-bold w-full my-4">Configurable Terms and Settings</h3>

    <mat-checkbox fxFlex="73%" labelPosition="before" formControlName="allowAttributeConfiguration" class="margin-b">
      Allow overriding select terms and settings in loan accounts
    </mat-checkbox>

    <div *ngIf="loanProductSettingsForm.value.allowAttributeConfiguration" formGroupName="allowAttributeOverrides"
         fxFlexFill fxLayout="row wrap" fxLayoutGap="2%" fxLayout.lt-md="column">

      <mat-checkbox fxFlex="48%" labelPosition="before" formControlName="amortizationType" class="margin-v">
        Amortization
      </mat-checkbox>

      <mat-checkbox fxFlex="48%" labelPosition="before" formControlName="interestType" class="margin-v">
        Interest method
      </mat-checkbox>

      <mat-checkbox fxFlex="48%" labelPosition="before" formControlName="transactionProcessingStrategyId"
                    class="margin-v">
        Repayment strategy
      </mat-checkbox>

      <mat-checkbox fxFlex="48%" labelPosition="before" formControlName="interestCalculationPeriodType"
                    class="margin-v">
        Interest calculation period
      </mat-checkbox>

      <mat-checkbox fxFlex="48%" labelPosition="before" formControlName="inArrearsTolerance" class="margin-v">
        Arrears tolerance
      </mat-checkbox>

      <mat-checkbox fxFlex="48%" labelPosition="before" formControlName="repaymentEvery" class="margin-v">
        Repaid every
      </mat-checkbox>

      <mat-checkbox fxFlex="48%" labelPosition="before" formControlName="graceOnPrincipalAndInterestPayment"
                    class="margin-v">
        Moratorium
      </mat-checkbox>

      <mat-checkbox fxFlex="48%" labelPosition="before" formControlName="graceOnArrearsAgeing" class="margin-v">
        Number of days a loan may be overdue before moving into arrears
      </mat-checkbox>

    </div>
    <div class="w-full">
      <hr/>
    </div>
    <div class="w-full gap-8 py-4 border-b-4" formGroupName="requiredDocuments">
      <h3 class="text-xl font-bold w-full">Required Documents for Loan</h3>
      <div class="grid grid-cols-1 md:grid-cols-2">
        <div class="col-span-1 py-4">
          <div class="flex flex-col checkbox">
            <mat-checkbox labelPosition="before" formControlName="isArticlesOfIncorporationActive" class="my-2">
              Articles of Incorporation
            </mat-checkbox>
            <mat-checkbox labelPosition="before" formControlName="isCreditReportActive" class="my-2">
              Credit Report
            </mat-checkbox>
            <mat-checkbox labelPosition="before" formControlName="isCommercialLeaseSpacesActive" class="my-2">
              Commercial Lease Spaces
            </mat-checkbox>
            <mat-checkbox labelPosition="before" formControlName="isOtherDocumentsActive" class="my-2">
              Add Other Documents ?
            </mat-checkbox>
          </div>
        </div>
        <div class="col-span-1 py-4">
          <div class="flex flex-col checkbox">
            <mat-checkbox labelPosition="before" formControlName="isTradeLicenceActive" class="my-2">
              Trade License
            </mat-checkbox>
            <mat-checkbox labelPosition="before" formControlName="isFranchiseAgreementsActive" class="my-2">
              Franchise agreements
            </mat-checkbox>
            <mat-checkbox labelPosition="before" formControlName="isContractsWithThirdPartyCompaniesActive" class="my-2">
              Contracts with third-party companies
            </mat-checkbox>
          </div>
        </div>
      </div>
    </div>
    <mat-card class="w-full mt-8 mb-8" *ngIf="loanProductSettingsForm.value.requiredDocuments.isOtherDocumentsActive">
      <mat-card-header class="flex items-center justify-between border-b-2 pb-2">
        <mat-card-title>More Documents</mat-card-title>
        <button type="button" mat-raised-button color="primary" (click)="addDoc()" >
          <fa-icon icon="plus" class="m-r-10"></fa-icon>
          Add
        </button>
      </mat-card-header>
      <mat-card-content formArrayName="otherDocuments">
        <ng-container *ngFor="let document of documents.controls; let i= index">
          <div formGroupName="{{i}}" class="flex items-center">
            <mat-form-field fxFlex="40%" class="mx-4">
              <mat-label>Document Name</mat-label>
              <input type="text" matInput formControlName="name">
            </mat-form-field>
            <button type="button" mat-button color="warn" mat-raised-button (click)="removeDoc(i)" class="btn-remove">
              <fa-icon icon="trash" size="sm"></fa-icon>
            </button>
          </div>
        </ng-container>
      </mat-card-content>
    </mat-card>
  </div>

  <div fxLayout="row" class="margin-t" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="2%">
    <button mat-raised-button matStepperPrevious>
      <fa-icon icon="arrow-left" class="m-r-10"></fa-icon>
      Previous
    </button>
    <button mat-raised-button matStepperNext>
      Next
      <fa-icon icon="arrow-right" class="m-l-10"></fa-icon>
    </button>
  </div>

</form>
