<mat-toolbar fxLayout="row" color="primary" id="mifosx-toolbar">

  <button mat-icon-button *ngIf="!sidenavCollapsed" (click)="toggleSidenav()" matTooltip="Toggle Hide/Open">
    <fa-icon icon="bars" size="lg"></fa-icon>
  </button>

  <button mat-icon-button *ngIf="sidenav.opened" (click)="toggleSidenavCollapse()" matTooltip="Toggle Collapse" fxHide.lt-md="true">
    <fa-icon *ngIf="!sidenavCollapsed" icon="chevron-left"></fa-icon>
    <fa-icon *ngIf="sidenavCollapsed" icon="chevron-right"></fa-icon>
  </button>

  <nav mat-tab-nav-bar backgroundColor="primary" class="ml-1">
    <div>
      <a #institution mat-tab-link class="tab-link" [routerLink]="['/clients']">
      <fa-icon class="mr-05" icon="university" size="lg"></fa-icon>
        {{ 'labels.menus.Clients' | translate }}
      </a>
      <a #accounting mat-tab-link class="tab-link" [routerLink]="['/accounting']">
        <fa-icon class="mr-05" icon="money-bill-alt" size="lg"></fa-icon>
        {{ 'labels.menus.Accounting' | translate }}
      </a>
      <a #reports mat-tab-link class="tab-link" [matMenuTriggerFor]="reportsMenu" #reportsMenuTrigger="matMenuTrigger" >
        <fa-icon class="mr-05" icon="chart-bar" size="lg"></fa-icon>
        {{ 'labels.menus.Reports' | translate }}
      </a>
      <a #admin mat-tab-link class="tab-link" [matMenuTriggerFor]="adminMenu" #adminMenuTrigger="matMenuTrigger">
        <fa-icon class="mr-05" icon="shield-alt" size="lg"></fa-icon>
        {{ 'labels.menus.Admin' | translate }}
      </a>

    </div>

  </nav>

  <span fxFlex="auto"></span>

  <div #globalSearch>
    <mifosx-search-tool></mifosx-search-tool>
  </div>

  <div #languageSelector>
    <mifosx-language-selector class="ml-1 language" fxHide.lt-md></mifosx-language-selector>
  </div>

<!--  <div #themePicker>-->
<!--    <mifosx-theme-picker fxHide.lt-md></mifosx-theme-picker>-->
<!--  </div>-->

  <div #notifications>
    <mifosx-notifications-tray fxHide.lt-md></mifosx-notifications-tray>
  </div>

  <div #appMenu>
    <button mat-icon-button class="ml-1 img-button" [matMenuTriggerFor]="applicationMenu">
      <img src="assets/images/user_placeholder.png">
    </button>
  </div>
</mat-toolbar>

<!-- Toolbar Menus -->
<mat-menu #reportsMenu="matMenu" [overlapTrigger]="false">
  <button mat-menu-item [routerLink]="['/reports']">{{ 'labels.menus.All' | translate }}</button>
  <button mat-menu-item [routerLink]="['/reports', 'Client']">{{ 'labels.menus.Clients' | translate }}</button>
  <button mat-menu-item [routerLink]="['/reports', 'Loan']">{{ 'labels.menus.Loans' | translate }}</button>
  <button mat-menu-item [routerLink]="['/reports', 'Savings']">{{ 'labels.menus.Savings' | translate }}</button>
  <button mat-menu-item [routerLink]="['/reports', 'Fund']">{{ 'labels.menus.Funds' | translate }}</button>
  <button mat-menu-item [routerLink]="['/reports', 'Accounting']">{{ 'labels.menus.Accounting' | translate }}</button>
  <button mat-menu-item [routerLink]="['/xbrl']">{{ 'labels.menus.XBRL' | translate }}</button>
</mat-menu>

<mat-menu #adminMenu="matMenu" [overlapTrigger]="false">
  <button mat-menu-item [routerLink]="['/users']">{{ 'labels.menus.Users' | translate }}</button>
  <button mat-menu-item [routerLink]="['/organization']">{{ 'labels.menus.Organization' | translate }}</button>
  <button mat-menu-item [routerLink]="['/system']">{{ 'labels.menus.System' | translate }}</button>
  <button mat-menu-item [routerLink]="['/products']">{{ 'labels.menus.Products' | translate }}</button>
  <button mat-menu-item [routerLink]="['/templates']">{{ 'labels.menus.Templates' | translate }}</button>
</mat-menu>

<mat-menu #selfServiceMenu="matMenu" [overlapTrigger]="false">
  <button mat-menu-item [routerLink]="['/self-service/users']">{{ 'labels.menus.User Management' | translate }}</button>
  <button mat-menu-item [routerLink]="['/self-service/app-configuration']">{{ 'labels.menus.App Configuration' | translate }}</button>
  <button mat-menu-item [routerLink]="['/self-service/task-management']">{{ 'labels.menus.Task Management' | translate }}</button>
</mat-menu>

<!-- Application User Help Menu -->
<mat-menu #applicationMenu="matMenu" [overlapTrigger]="false">
  <button mat-menu-item [routerLink]="['/profile']">
    <mat-icon>
      <fa-icon icon="user" size="sm"></fa-icon>
    </mat-icon>
    <span>{{ 'labels.menus.Profile' | translate }}</span>
  </button>
  <button mat-menu-item [routerLink]="['/settings']">
    <mat-icon>
      <fa-icon icon="cog" size="sm"></fa-icon>
    </mat-icon>
    <span>{{ 'labels.menus.Settings' | translate }}</span>
  </button>
  <button mat-menu-item (click)="logout()" id="logout">
    <mat-icon>
      <fa-icon icon="sign-out-alt" size="sm"></fa-icon>
    </mat-icon>
    <span>{{ 'labels.menus.Sign Out' | translate }}</span>
  </button>
</mat-menu>


<ng-template #templateInstitution let-popover="popover">
  <h2>{{ 'labels.menus.Institution' | translate }}</h2>
  <p class="mw300">This button will be used for individual accounts, groups and centers</p>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn" (click)="popover.close();configurationWizardService.closeConfigWizard()">Close</button>
    <button mat-raised-button color="primary" (click)="popover.close();showPopover(templateAccounting, accounting)">Next</button>
  </div>
</ng-template>

<ng-template #templateAccounting let-popover="popover">
  <h2>{{ 'labels.menus.Accounting' | translate }}</h2>
  <p class="mw300">This button opens the menu to access the system tools required by the accounting department.</p>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn" (click)="popover.close();configurationWizardService.closeConfigWizard()">Close</button>
    <button mat-raised-button color="primary" (click)="popover.close();showPopover(templateInstitution, institution)">Back</button>
    <button mat-raised-button color="primary" (click)="popover.close();showPopover(templateReports, reports)">Next</button>
  </div>
</ng-template>

<ng-template #templateReports let-popover="popover">
  <h2>{{ 'labels.menus.Reports' | translate }}</h2>
  <p class="mw300">Dropdown provides access to all types of system reports including the defualt reports provided by the Mifos and system reports that will be developed by the organization.</p>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn" (click)="popover.close();configurationWizardService.closeConfigWizard()">Close</button>
    <button mat-raised-button color="primary" (click)="popover.close();showPopover(templateAccounting, accounting)">Back</button>
    <button mat-raised-button color="primary" (click)="popover.close();showPopover(templateAdmin, admin)">Next</button>
  </div>
</ng-template>

<ng-template #templateAdmin let-popover="popover">
  <h2>{{ 'labels.menus.Admin' | translate }}</h2>
  <p class="mw300">This is where the system is setup according to your organization including users, organization, products and templates.</p>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn" (click)="popover.close();configurationWizardService.closeConfigWizard()">Close</button>
    <button mat-raised-button color="primary" (click)="popover.close();showPopover(templateReports, reports)">Back</button>
    <button mat-raised-button color="primary" (click)="popover.close();showPopover(templateSelfService, selfService)">Next</button>
  </div>
</ng-template>

<ng-template #templateSelfService let-popover="popover">
  <h2>{{ 'labels.menus.Self Service' | translate }}</h2>
  <p class="mw300">Dropdown provides access to self service user administrative portal, app configuration and task management.</p>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn" (click)="popover.close();configurationWizardService.closeConfigWizard()">Close</button>
    <button mat-raised-button color="primary" (click)="popover.close();showPopover(templateAdmin, admin)">Back</button>
    <button mat-raised-button color="primary" (click)="popover.close();showPopover(templateConfigWizard, configWizard)">Next</button>
  </div>
</ng-template>

<ng-template #templateConfigWizard let-popover="popover">
  <h2>{{ 'labels.menus.Configuration Wizard' | translate }}</h2>
  <p class="mw300">This buttons opens up the configuration wizard to help the user in initial setup and application tour.</p>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn" (click)="popover.close();configurationWizardService.closeConfigWizard()">Close</button>
    <button mat-raised-button color="primary" (click)="popover.close();showPopover(templateSelfService, selfService)">Back</button>
    <button mat-raised-button color="primary" (click)="popover.close();showPopover(templateGlobalSearch, globalSearch)">Next</button>
  </div>
</ng-template>

<ng-template #templateGlobalSearch let-popover="popover">
  <h2>{{ 'labels.menus.Global Search' | translate }}</h2>
  <p class="mw300">This will allow the user to quickly navigate the system selecting entity while searching makes the navigation more robust.</p>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn" (click)="popover.close();configurationWizardService.closeConfigWizard()">Close</button>
    <button mat-raised-button color="primary" (click)="popover.close();showPopover(templateConfigWizard, configWizard)">Back</button>
    <button mat-raised-button color="primary" (click)="popover.close();showPopover( templateLanguageSelector, languageSelector)">Next</button>
  </div>
</ng-template>

<ng-template #templateLanguageSelector let-popover="popover">
  <h2>{{ 'labels.menus.Language Selector' | translate }}</h2>
  <p class="mw300">Organization can select a language here from the available languages here.</p>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn" (click)="popover.close();configurationWizardService.closeConfigWizard()">Close</button>
    <button mat-raised-button color="primary" (click)="popover.close();showPopover(templateGlobalSearch, globalSearch)">Back</button>
    <button mat-raised-button color="primary" (click)="popover.close();showPopover(templateThemePicker, themePicker)">Next</button>
  </div>
</ng-template>

<ng-template #templateThemePicker let-popover="popover">
  <h2>{{ 'labels.menus.Theme Picker' | translate }}</h2>
  <p class="mw300">This will be used to choose a theme as per organization will.</p>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn" (click)="popover.close();configurationWizardService.closeConfigWizard()">Close</button>
    <button mat-raised-button color="primary" (click)="popover.close();showPopover( templateLanguageSelector, languageSelector)">Back</button>
    <button mat-raised-button color="primary" (click)="popover.close();showPopover(templateNotifications, notifications)">Next</button>
  </div>
</ng-template>

<ng-template #templateNotifications let-popover="popover">
  <h2>{{ 'labels.menus.Notifications' | translate }}</h2>
  <p class="mw300">This shows all the notifications.</p>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn" (click)="popover.close();configurationWizardService.closeConfigWizard()">Close</button>
    <button mat-raised-button color="primary" (click)="popover.close();showPopover(templateThemePicker, themePicker)">Back</button>
    <button mat-raised-button color="primary" (click)="popover.close();showPopover(templateAppMenu, appMenu)">Next</button>
  </div>
</ng-template>

<ng-template #templateAppMenu let-popover="popover">
  <h2>{{ 'labels.menus.Application Menu' | translate }}</h2>
  <p class="mw300">This will have logged in user settings and sign out button.</p>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn" (click)="popover.close();configurationWizardService.closeConfigWizard()">Close</button>
    <button mat-raised-button color="primary" (click)="popover.close();showPopover(templateNotifications, notifications)">Back</button>
    <button mat-raised-button color="primary" (click)="popover.close(); nextStep()">Next</button>
  </div>
</ng-template>
