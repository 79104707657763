<div class="container m-b-20" fxLayout="row" fxLayoutAlign="end" fxLayoutGap="20px">
  <div #buttonCreateCharge class="in-block">
    <button mat-raised-button color="primary" [routerLink]="['create']" *mifosxHasPermission="'CREATE_CHARGE'">
      <fa-icon icon="plus" class="m-r-10"></fa-icon>
      Create Charge
    </button>
  </div>
</div>

<div class="container">

  <div #filter fxLayout="row" fxLayoutGap="20px">
    <mat-form-field fxFlex>
      <mat-label>{{ 'labels.inputs.Filter' | translate }}</mat-label>
      <input matInput (keyup)="applyFilter($event.target.value)">
    </mat-form-field>
  </div>

  <div #chargesTable class="mat-elevation-z8">

    <table mat-table [dataSource]="dataSource" matSort>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
        <td mat-cell *matCellDef="let charge"> {{ charge.name }} </td>
      </ng-container>

      <ng-container matColumnDef="chargeAppliesTo">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Applies To </th>
        <td mat-cell *matCellDef="let charge"> {{ charge.chargeAppliesTo.value }} </td>
      </ng-container>

      <ng-container matColumnDef="chargeTimeType">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Time </th>
        <td mat-cell *matCellDef="let charge"> {{ charge.chargeTimeType.value }} </td>
      </ng-container>

      <ng-container matColumnDef="chargeCalculationType">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Calculation </th>
        <td mat-cell *matCellDef="let charge"> {{ charge.chargeCalculationType.value }} </td>
      </ng-container>

      <ng-container matColumnDef="amount">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Amount </th>
        <td mat-cell *matCellDef="let charge" class="r-amount"> {{ charge.amount | formatNumber }} </td>
      </ng-container>

      <ng-container matColumnDef="penalty">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Is Penalty? </th>
        <td mat-cell *matCellDef="let charge" class="center">
            <fa-icon *ngIf="charge.penalty" icon="check-circle" size="lg" class="ispenalty" matTooltip="Yes" matTooltipPosition="right"></fa-icon>
            <fa-icon *ngIf="!charge.penalty" icon="times-circle" size="lg" class="nopenalty" matTooltip="No" matTooltipPosition="right"></fa-icon>
        </td>
      </ng-container>

      <ng-container matColumnDef="active">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Is Active? </th>
        <td mat-cell *matCellDef="let charge" class="center">
          <div [className]="charge.active === true ? 'true' : 'false'">
            <fa-icon matTooltip="{{ charge.active === true ? 'Active' : 'Inactive' }}" matTooltipPosition="right" icon="circle" size="lg"></fa-icon>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" [routerLink]="[row.id]" class="select-row"></tr>

    </table>

    <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons></mat-paginator>

  </div>

</div>

<ng-template #templateButtonCreateCharge let-popover="popover">
  <h2>Create Charge</h2>
  <p class="mw300">This option allows you to create new charges. It supports Loan charges, Savings & deposits charges and Client charges.</p>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn" (click)="popover.close();configurationWizardService.closeConfigWizard()">Close</button>
    <button mat-raised-button color="primary" (click)="popover.close();previousStep()">Back</button>
    <button mat-raised-button color="primary" (click)="popover.close();showPopover(templateFilter,filter, 'bottom', true)">Next</button>
  </div>
</ng-template>

<ng-template #templateFilter let-popover="popover">
  <h4>Search bar to filter charges.</h4>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn" (click)="popover.close();configurationWizardService.closeConfigWizard()">Close</button>
    <button mat-raised-button color="primary" (click)="popover.close();showPopover(templateButtonCreateCharge,buttonCreateCharge , 'bottom', true)">Back</button>
    <button mat-raised-button color="primary" (click)="popover.close();showPopover(templateChargesTable,chargesTable, 'top', true)">Next</button>
  </div>
</ng-template>

<ng-template #templateChargesTable let-popover="popover">
  <h4 class="mw300">List of charges in the organization. For more details click: <a href="https://mifosforge.jira.com/wiki/spaces/docs/pages/72908813/Charges" target="_blank">Charges</a></h4>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn" (click)="popover.close();configurationWizardService.closeConfigWizard()">Close</button>
    <button mat-raised-button color="primary" (click)="popover.close();showPopover(templateFilter,filter, 'bottom', true)">Back</button>
    <button mat-raised-button color="primary" (click)="popover.close();nextStep()">Next</button>
  </div>
</ng-template>
