<mat-dialog-content>
  <div class="container mx-auto">
      <div class="actions flex flex-row justify-end py-4">
        <mat-icon class="cursor-pointer mr-2" (click)="downloadPdf()">
          <fa-icon icon="download" size="lg"></fa-icon>
        </mat-icon>
        <mat-icon class="cursor-pointer" (click)="onNoClick()">
          <fa-icon icon="times" size="lg"></fa-icon>
        </mat-icon>
      </div>
      <div [innerHTML]="data?.content" id="pdfView" style="padding: 5rem;"></div>
  </div>
</mat-dialog-content>
